<template>
  <div
    class="notification-item"
    :class="{ 'with-link-to-detail': hasLinkToDetail, 'already-read': alreadyRead, 'action-notification': isActionNotification(props.notification), 'announcement': isAnnouncement(props.notification) }"
    @click.stop="handleClick"
  >
    <div class="notification-item-body">
      <div class="notification-item-notified-at">
        <span class="notified-at">{{ notifiedAt }}</span>
        <template v-if="tag != null">
          <ElTag
            :type="tag.type"
            effect="plain"
            size="small"
          >
            {{ tag.text }}
          </ElTag>
        </template>
      </div>
      <div class="notification-item-detail">
        <template v-if="isActionNotification(props.notification)">
          <span class="notification-item-target-name">{{ $t(props.notification.targetName) }}</span>
          {{ $t(props.notification.message) }}
        </template>
        <template v-else-if="isAnnouncement(props.notification)">
          <div class="notification-item-title">
            {{ $t(props.notification.title) }}
          </div>
          <div class="notification-item-content">
            {{ $t(props.notification.content) }}
          </div>
        </template>
      </div>
    </div>
    <div class="notification-item-right">
      <div
        v-if="hasLinkToDetail"
        class="link-to-detail"
      >
        <ArrowRight
          size="medium"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ArrowRight } from '@element-plus/icons-vue';
import { computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { NotificationApiService } from '@/api/user/resources/notification';
import { useWait } from '@/composable/vue-wait';
import { formatShorterDateTime } from '@/utils/date-time';
import { isActionNotification, isAnnouncement, type TNotification } from './types';

type TReadTrigger = 'click' | 'render';

type TProps = {
  notification: TNotification;
  readTrigger: TReadTrigger;
};

const props = defineProps<TProps>();
const { doActionWithWait } = useWait();
const i18n = useI18n();

const alreadyRead = ref(props.notification.alreadyRead);
const notifiedAt = computed(() => formatShorterDateTime(isAnnouncement(props.notification) ? props.notification.startAt : props.notification.createdAt));
const hasLinkToDetail = computed(() => props.notification.url != null);
const tag = computed(() => isActionNotification(props.notification) ? {
  type: props.notification.type === 'error' ? 'danger' : 'success',
  text: i18n.t(`notification.actionNotification.type.${props.notification.type}`),
} : null);

onBeforeMount(async () => {
  if (props.readTrigger === 'render') {
    await readNotification();
  }
});

const handleClick = async () => {
  if (props.notification.url == null) return;

  window.open(props.notification.url, '_blank');

  if (props.readTrigger === 'click') {
    await readNotification();
  }
};

const readNotification = async () => {
  if (alreadyRead.value) return;

  const api = new NotificationApiService();
  await doActionWithWait('readNotificationWait', async () => {
    await api.readNotification(props.notification);
    alreadyRead.value = true;
  });
};
</script>

<style lang="scss" scoped>
.notification-item {
  &.action-notification {
    .notification-item-notified-at {
      padding-bottom: calc($basespace-100 - 2px);
    }
    .el-tag {
      padding: $basespace-50 $basespace-100;
      margin-left: $basespace-100;
      margin-bottom: 2px; // 視差調整
    }
  }
  &.announcement {
    .notification-item-notified-at {
      padding-bottom: $basespace-100;
    }
  }
}
.notification-item {
  @include m-flex-center;
  padding: $basespace-200 $basespace-400;

  &.with-link-to-detail {
    cursor: pointer;
  }
  &.already-read {
    background-color: $bdcolor-light;
  }
  &-body {
    flex: 1;
  }
  &-notified-at {
    .notified-at {
      font-size: $fontsize-100;
      color: $textcolor-light;
    }
  }
  &-target-name, &-title {
    font-weight: $font-weight-bold;
  }
  &-title {
    padding-bottom: $basespace-50;
  }
  &-detail {
    @include m-break-word-pre-wrap;
  }
  &-right {
    @include m-flex-center;
    padding-left: $basespace-100;
    .link-to-detail {
      width: $fontsize-300;
      height: $fontsize-300;
    }
  }
}
</style>

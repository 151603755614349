<template>
  <div
    v-infinite-scroll="loadMore"
    class="notification-list"
    :infinite-scroll-disabled="infiniteScrollDisabled"
    infinite-scroll-distance="5"
  >
    <BEmptyBox 
      v-if="!$wait.is(KEY_OF_INFINITE_LOADING_WAIT) && !notifications.length"
      display-only
      class="notification-list-empty"
    >
      <span>{{ $t('notification.empty') }}</span>
    </BEmptyBox>
    <template v-else>
      <NotificationItem
        v-for="notification in notifications"
        :key="notification.id"
        :notification="(notification as TNotification)"
        :read-trigger="readTrigger"
      />
      <div
        v-if="$wait.is(KEY_OF_INFINITE_LOADING_WAIT)"
        class="loading-icon-container"
      >
        <BElementIcon name="Loading" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { upperFirst } from 'lodash';
import { computed, onMounted } from 'vue';
import { NotificationApiService } from '@/api/user/resources/notification';
import { useInfiniteLoading } from '@/composable/infinite-loading';
import NotificationItem from './NotificationItem.vue';
import { type TNotification, NotificationType } from './types';

type TProps = {
  notificationType: NotificationType;
};

const props = defineProps<TProps>();

const KEY_OF_INFINITE_LOADING_WAIT = `get${upperFirst(props.notificationType)}NotificationsWait`;
const readTrigger = computed(() => {
  return props.notificationType === 'action_notification' ? 'click' : 'render';
});

onMounted(() => {
  handleOnMounted();
});

const api = new NotificationApiService();
const fetchNotifications = async (page = 1) => {
  return await api.getUsersNotifications(props.notificationType, {
    request: { currentPage: page },
  });
};
const { items: notifications, loadMore, infiniteScrollDisabled, handleAfterOpen: handleOnMounted } = useInfiniteLoading<TNotification>(fetchNotifications, KEY_OF_INFINITE_LOADING_WAIT);
</script>

<style lang="scss" scoped>
.notification-list {
  @include m-fixed-height(300px);
  overflow-y: auto;
  :deep(.notification-item) {
    border-bottom: 1px solid $bdcolor-light;
  }
  &-empty {
    margin: $basespace-200;
    padding: $basespace-200 $basespace-400;
    color: $textcolor-light;
  }
  .loading-icon-container {
    text-align: center;
    padding: $basespace-200;
  }
}
</style>

<template>
  <BHeightAdjuster>
    <BModalBody>
      <div class="setting-message">
        {{ $t('callTarget.setting.message') }}
      </div>
      <div
        id="list-content"
        class="list-content"
      >
        <div v-loading="loadingItems">
          <div class="item-content">
            <BEmptyBox
              v-if="!$wait.is('getCustomFieldsWait') && sortedCustomFields.length === 0"
              class="py-400"
              display-only
            >
              <slot name="text">
                {{ $t('customField.empty') }}
              </slot>
            </BEmptyBox>
            <Draggable
              v-model="sortedCustomFields"
              class="item-row"
              item-key="id"
              handle=".handle"
              :animation="300"
              @end="updateCustomFields"
            >
              <template #item="{ element: field }">
                <div>
                  <BLayout
                    class="py-100"
                    align-center
                    justify-space-between
                  >
                    <BLayout
                      class="mr-100"
                      align-center
                    >
                      <BIcon
                        class="handle"
                        size="small"
                        type="gray"
                      >
                        drag_indicator
                      </BIcon>
                      <BTooltip
                        top
                        :content="$t(`customField.dataType.enum.${convertToCamelcaseKeys(field.dataType)}`)"
                      >
                        <BDataTypeIcon
                          :data-type="field.dataType"
                        />
                      </BTooltip>
                      <BEditableContent
                        :value="field.name"
                        :placeholder="$t('customField.name')"
                        required
                        :loading="updateLoading"
                        @check="handleUpdateName($event, field.id)"
                      />
                      <BEditableContent
                        class="system-reference-name-input"
                        :value="field.systemReferenceName"
                        :placeholder="$t('customField.systemReferenceName')"
                        required
                        :loading="updateLoading"
                        @check="handleUpdateSystemReferenceKey($event, field.id)"
                      />
                    </BLayout>
                    <BMoreMenu>
                      <BPopover
                        v-if="field.deleteDisable"
                        placement="top"
                        width="240"
                        trigger="hover"
                      >
                        <template #reference>
                          <div>
                            <BBtn
                              flat
                              fit
                              size="small"
                              disabled
                            >
                              {{ $t('customField.delete') }}
                            </BBtn>
                          </div>
                        </template>
                        <div>{{ $t('customField.validation.usedMappingBySalesforce') }}</div>
                      </BPopover>
                      <BBtn
                        v-else
                        flat
                        fit
                        size="small"
                        @click="() => deleteCustomField(field)"
                      >
                        {{ $t('customField.delete') }}
                      </BBtn>
                    </BMoreMenu>
                  </BLayout>
                  <CustomFieldOption
                    v-if="field.options"
                    :custom-field-option="field.options"
                    :custom-field-id="field.id"
                    :custom-field-name="field.name"
                    :loading="updateLoading"
                    @delete="handleDeleteCustomFieldOption"
                  />
                </div>
              </template>
            </Draggable>
          </div>
        </div>
      </div>
    </BModalBody>
    <BModalFooter>
      <CustomFieldCreatingForm class="footer" />
    </BModalFooter>
  </BHeightAdjuster>
</template>

<script>
import lodash from 'lodash';
import { mapWaitingActions } from 'vue-wait';
import Draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import CustomFieldCreatingForm from '@/components/organisms/user/setting/item/CustomFieldCreatingForm.vue';
import CustomFieldOption from '@/components/organisms/user/setting/item/CustomFieldOption.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';

export default {
  components: {
    Draggable,
    CustomFieldOption,
    CustomFieldCreatingForm,
  },
  mixins: [inputValidation, errorHandler],
  setup() {
    const { isAvailableSalesforceLinkFeature } = useAvailableFeatureCheck();
    return {
      isAvailableSalesforceLinkFeature,
    };
  },
  data () {
    return {
      sortedCustomFields: [],
    };
  },
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    createLoading() {
      return this.$wait.is(['createCustomFieldWait', 'getCustomFieldsWait']);
    },
    updateLoading() {
      return this.$wait.is(['updateCustomFieldWait', 'getCustomFieldsWait']);
    },
    loadingItems() {
      return this.$wait.is(['getCustomFieldsWait', 'deleteCustomFieldWait', 'deleteCustomFieldOptionWait']);
    },
  },
  watch: {
    customFields (newValue) {
      this.sortedCustomFields = this.sortOrder(lodash.cloneDeep(newValue));
    },
  },
  created () {
    this.getCustomFieldsAction();
  },
  methods: {
    ...mapWaitingActions('user', {
      getCustomFieldsAction: 'getCustomFieldsWait',
      updateCustomFieldAction: 'updateCustomFieldWait',
      updateCustomFieldsAction: 'updateCustomFieldsWait',
      deleteCustomFieldAction: 'deleteCustomFieldWait',
    }),
    sortOrder (array) {
      if (!array) { return null; }

      return array.slice().sort(function (a, b) {
        return a.sortOrder > b.sortOrder;
      });
    },
    async handleUpdateName (newFieldName, customFieldId) {
      await this.updateCustomFieldAction({
        customFieldId,
        body: { customField: { name: newFieldName } },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
    },
    async handleUpdateSystemReferenceKey (newValue, customFieldId) {
      const ok = await this.$bitterAlert.show({
        title: this.$t('customField.title'),
        text: `${this.$t('customField.systemReferenceNameChangingNotice')}`,
      });
      if (!ok) { return; }
      await this.updateCustomFieldAction({
        customFieldId,
        body: { customField: { systemReferenceName: newValue } },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
    },
    async updateCustomFields () {
      const customFields = this.sortedCustomFields.map((customField, index) => {
        customField.sortOrder = index;
        return customField;
      });
      await this.updateCustomFieldsAction({
        body: { customFields },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
    },
    async deleteCustomField (field) {
      const check = await this.$bitterAlert.show({
        title: this.$t('customField.title'),
        text: `${this.$t('general.confirm.actionWithTarget', { target: field.name, action: this.$t('general.delete.text') })}\n\n${this.$t('customField.deleteMessage')}`,
      });
      if (!check) { return; }
      await this.deleteCustomFieldAction({
        customFieldId: field.id,
      });
      await this.refresh();
    },
    async handleDeleteCustomFieldOption() {
      await this.refresh();
    },
    async refresh() {
      await this.getCustomFieldsAction();
      const listContent = document.getElementById('list-content');
      listContent.scrollTo({ top: listContent.scrollHeight, left: 0, behavior: 'smooth' });
    },
    convertToCamelcaseKeys (dataType) {
      if (dataType.indexOf('_') == -1) {
        return dataType;
      }
      return dataType.split('_').map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join('');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.b-modal-body) {
  margin-top: 20px;
  padding: 0 32px 20px;
  .list-content {
    overflow-y: auto;
    .item-content {
      height: calc(100vh - 320px);
    }
    .item-row {
      margin: 0;
      @media only screen and (min-width: 959px) {
        width: 50%;
      }
      .b-input-box {
        margin-left: 8px;
      }
    }
  }
}

.list-content {
  .system-reference-name-input {
    width: 280px;
    word-break: break-all
  }
}

.setting-message {
  color: $textcolor-light;
  margin-bottom: $basespace-400;
}

.handle {
  cursor: grab;
  margin-right: $basespace-50;
}

.form-box {
  background: $bgcolor-base;
  padding: $basespace-200;
}

.sortable-chosen {
  opacity: 0.5;
  background-color: $bgcolor-base;
}
.sortable-ghost {
  background-color: $bgcolor-dark;
}

.footer {
  padding-top: $basespace-300;
}
</style>

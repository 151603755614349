import type { AxiosResponse } from 'axios';
import ApiService from '@/api/api_service';
import type { ApiArguments } from '@/api/api_service';
import type { GetUnreadNotificationsCount200Response, NotificationApiGetActionNotificationsRequest, NotificationApiGetAnnouncementsRequest, PaginationMeta } from '@/api/openapi';
import { NotificationApi } from '@/api/openapi';
import { isActionNotification, isAnnouncement, NotificationType, TNotification } from '@/components/organisms/user/notificationPopover/types';

export class NotificationApiService extends ApiService {
  api = new NotificationApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getUsersNotifications(notificationType: NotificationType, { request, options }: ApiArguments<NotificationApiGetActionNotificationsRequest | NotificationApiGetAnnouncementsRequest>): Promise<{ items: TNotification[], paginationMeta: PaginationMeta }> {
    if (notificationType === 'action_notification') {
      const { data } = await this.api.getActionNotifications({
        readType: 'unread',
        ...request,
      }, options);

      return { items: data.actionNotifications, paginationMeta: data.meta };
    } else if (notificationType === 'announcement') {
      const { data } = await this.api.getAnnouncements({
        readType: 'all',
        ...request,
      }, options);

      return { items: data.announcements, paginationMeta: data.meta };
    }
  }

  async getUnreadNotificationsCount(params: ApiArguments<never> = {}): Promise<AxiosResponse<GetUnreadNotificationsCount200Response>> {
    const { options } = params;
    return this.api.getUnreadNotificationsCount(options);
  }

  async readNotification(notification: TNotification, { options }: ApiArguments<void> = {}): Promise<AxiosResponse<void>> {
    if (isActionNotification(notification)) {
      return this.api.postActionNotificationRead({
        actionNotificationId: notification.id,
      }, options);
    } else if (isAnnouncement(notification)) {
      return this.api.postAnnouncementRead({
        announcementId: notification.id,
      }, options);
    }
  }
}

/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetUsersForAdmin200Response } from '../models';
/**
 * UserForAdminApi - axios parameter creator
 * @export
 */
export const UserForAdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザー一覧取得API
         * @summary ユーザー一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {string} [email] メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForAdmin: async (page?: number, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserForAdminApi - functional programming interface
 * @export
 */
export const UserForAdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserForAdminApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザー一覧取得API
         * @summary ユーザー一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {string} [email] メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersForAdmin(page?: number, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersForAdmin200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersForAdmin(page, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserForAdminApi - factory interface
 * @export
 */
export const UserForAdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserForAdminApiFp(configuration)
    return {
        /**
         * ユーザー一覧取得API
         * @summary ユーザー一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {string} [email] メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersForAdmin(page?: number, email?: string, options?: any): AxiosPromise<GetUsersForAdmin200Response> {
            return localVarFp.getUsersForAdmin(page, email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserForAdminApi - interface
 * @export
 * @interface UserForAdminApi
 */
export interface UserForAdminApiInterface {
    /**
     * ユーザー一覧取得API
     * @summary ユーザー一覧取得API
     * @param {number} [page] infinityLoading用ページ番号
     * @param {string} [email] メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserForAdminApiInterface
     */
    getUsersForAdmin(page?: number, email?: string, options?: AxiosRequestConfig): AxiosPromise<GetUsersForAdmin200Response>;

}

/**
 * Request parameters for getUsersForAdmin operation in UserForAdminApi.
 * @export
 * @interface UserForAdminApiGetUsersForAdminRequest
 */
export interface UserForAdminApiGetUsersForAdminRequest {
    /**
     * infinityLoading用ページ番号
     * @type {number}
     * @memberof UserForAdminApiGetUsersForAdmin
     */
    readonly page?: number

    /**
     * メールアドレス
     * @type {string}
     * @memberof UserForAdminApiGetUsersForAdmin
     */
    readonly email?: string
}

/**
 * UserForAdminApi - object-oriented interface
 * @export
 * @class UserForAdminApi
 * @extends {BaseAPI}
 */
export class UserForAdminApi extends BaseAPI implements UserForAdminApiInterface {
    /**
     * ユーザー一覧取得API
     * @summary ユーザー一覧取得API
     * @param {UserForAdminApiGetUsersForAdminRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserForAdminApi
     */
    public getUsersForAdmin(requestParameters: UserForAdminApiGetUsersForAdminRequest = {}, options?: AxiosRequestConfig) {
        return UserForAdminApiFp(this.configuration).getUsersForAdmin(requestParameters.page, requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }
}

<template>
  <div
    class="expandable-text"
    :class="{ 'expanded': isExpanded }"
    @click="handleClick"
  >
    <div class="content">
      {{ text }}
    </div>
    <BIcon
      class="expand-icon"
    >
      arrow_drop_up
    </BIcon>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

type TProps = {
  text: string;
  nonExpandedTextLine?: number;
};

withDefaults(defineProps<TProps>(), {
  nonExpandedTextLine: 2,
});
const isExpanded = ref(false);

const handleClick = () => {
  isExpanded.value = !isExpanded.value;
};
</script>

<style lang="scss" scoped>
.expandable-text {
  @include m-flex-align-start;
  cursor: pointer;
  .content {
    flex: 1;
    line-height: 1.3;
    padding-right: $basespace-100;
  }
  &:not(.expanded) {
    .content {
      @include m-truncate-text-multi-line(v-bind('nonExpandedTextLine'));
    }
  }
  &.expanded {
    .content {
      @include m-break-word-pre-wrap;
    }

    .expand-icon {
      transform: rotate(180deg);
    }
  }
}
</style>

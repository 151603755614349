<template>
  <div class="pt-600">
    <BContainer>
      <RouterLink
        v-if="$can('admin_owner')"
        :to="{ name: 'AdminAdmins' }"
      >
        {{ $t(`adminManagement.title`) }}
      </RouterLink>
      <RouterLink
        class="ml-400"
        :to="{ name: 'AdminSendgridSubUsers' }"
      >
        {{ $t(`sendgridSubUserManagement.title`) }}
      </RouterLink>
      <RouterLink
        class="ml-400"
        :to="{ name: 'AdminUsers' }"
      >
        {{ $t(`userForAdmin.title`) }}
      </RouterLink>
      <div class="pt-600">
        <BCard shadow>
          <BLayout>
            <BFlex :span="16">
              <BBtn
                type="primary"
                @click="openClientNewDialog"
              >
                {{ $t(`client.title`) + $t(`general.register.text`) }}
              </BBtn>
            </BFlex>
            <BFlex :span="8">
              <BInput
                v-model="searchWord"
                class="pl-600"
                :placeholder="$t('clientManagement.search.placeholder')"
                @update:model-value="resetCurrentPage"
              />
            </BFlex>
          </BLayout>
          <ClientsList
            :search-word="searchWord"
            :current-page="currentPage"
            @change-current-page="changeCurrentPage"
          />
        </BCard>
      </div>
      <FormDialog
        :dialog="clientNewDialog"
        width="500"
      >
        <template #form>
          <ClientForm
            ref="clientNewForm"
            :loading="$wait.is('createClientWait')"
            @save="createClient"
            @cancel="closeClientNewDialog"
          >
            <template #title>
              <span class="headline">{{ $t(`client.title`) + $t(`general.register.text`) }}</span>
            </template>
          </ClientForm>
        </template>
      </FormDialog>
    </BContainer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import ClientForm from '@/components/organisms/admin/ClientForm.vue';
import ClientsList from '@/components/organisms/admin/ClientsList.vue';

export default defineComponent({
  components: {
    ClientForm,
    ClientsList,
  },
  data() {
    return {
      clientNewDialog: false,
      searchWord: '',
      currentPage: 1,
    };
  },
  methods: {
    ...mapWaitingActions('admin', {
      createClientAction: 'createClientWait',
    }),
    openClientNewDialog() {
      this.clientNewDialog = true;
    },
    closeClientNewDialog() {
      this.clientNewDialog = false;
      this.$refs.clientNewForm.reset();
    },
    resetCurrentPage() {
      this.currentPage = 1;
    },
    changeCurrentPage(value) {
      this.currentPage = value;
    },
    async createClient(client) {
      await this.createClientAction({ body: { client } });
      this.clientNewDialog = false;
      this.$refs.clientNewForm.reset();
      this.$toast.show(this.$t('general.done.of', { action: this.$t('general.save.text') }));
    },
  },
});
</script>

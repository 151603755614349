<template>
  <LeadHistoryCard v-bind="attributes">
    <template #date>
      <div>{{ historiedAt }}</div>
    </template>
    <BListItem
      v-if="isExistUrl"
      class="url-container"
    >
      <template #header>
        <div>{{ $t('mailEvent.url') }}</div>
      </template>
      <div class="url">
        {{ sendgridEvent.url }}
      </div>
    </BListItem>
    <BError 
      v-if="sendgridEvent.isFailed"
      :error-title="$t('mailEvent.failedInfoTitle')"
      :error-info="$t(`mailEvent.${props.sendgridEvent.localEvent}Info`, { email: sendgridEvent.email })"
    />
  </LeadHistoryCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import LeadHistoryCard from '@/components/organisms/user/lead/history/LeadHistoryCard.vue';
import { TObject } from '@/types';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  sendgridEvent: TObject & {
    localEvent: string;
    historiedAt: string;
    eventCount: number;
  }; // TODO: 型定義
  expand: boolean;
};

const props = defineProps<TProps>();
const i18n = useI18n();

const title = computed(() => {
  if (props.sendgridEvent == null || props.sendgridEvent.localEvent == null || props.sendgridEvent.subject == null) return i18n.t('mailEvent.unknown');

  return i18n.t(`mailEvent.${props.sendgridEvent.localEvent}`, { subject: props.sendgridEvent.subject });
});

const isExistUrl = computed(() => {
  return props.sendgridEvent && props.sendgridEvent.localEvent === 'click' && typeof props.sendgridEvent.url === 'string';
});

const needsToggle = computed(() => {
  return isExistUrl.value || props.sendgridEvent.isFailed;
});

const historiedAt = computed(() => {
  return formatShorterDateTime(props.sendgridEvent.historiedAt);
});

const iconTooltip = computed(() => {
  if (props.sendgridEvent.eventCount <= 1) return '';
  if (!['open', 'click'].includes(props.sendgridEvent.localEvent) && !props.sendgridEvent.isFailed) return '';

  return i18n.t('mailNotificationEvent.eventCount', { count: props.sendgridEvent.eventCount });
});

const icon = computed<{ fileName: string; type: string; eventEmotion?: string; }>(() => {
  if (props.sendgridEvent == null || props.sendgridEvent.localEvent == null) {
    return { fileName: 'remove', type: 'hot' };
  }
  const iconSetting = {
    fileName: 'mail',
    type: 'hot',
    eventEmotion: undefined,
  };
  switch (props.sendgridEvent.localEvent) {
    case 'open':
      iconSetting.fileName = 'drafts';
      iconSetting.eventEmotion = 'positive';
      break;
    case 'click':
      iconSetting.fileName = 'touch_app';
      iconSetting.eventEmotion = 'positive';
      break;
    case 'delivered':
      iconSetting.fileName = 'golf_course';
      iconSetting.eventEmotion = 'positive';
      break;
    default:
      break;
  }
  return iconSetting;
});

const attributes = computed(() => ({
  text: title.value,
  icon: icon.value.fileName,
  iconType: icon.value.type,
  expand: props.expand,
  toggle: needsToggle.value,
  canNotExpand: !needsToggle.value,
  isEvent: true,
  eventEmotion: icon.value.eventEmotion,
  iconTooltip: iconTooltip.value,
}));
</script>

<style lang="scss" scoped>
:deep(.lead-history-expand-indicator) {
  height: 0;
}
.url-container {
  margin: 10px 0;
}
</style>

<template>
  <BIconWithActionMenu
    ref="bIconWithActionMenuContainer"
    class="reminder-container"
    :width="720"
    :badge-count="todayActions.length"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="fetchReminder"
    @after-open="handleAfterOpen"
  >
    <template #icon>
      <BIcon rounded>
        tv_options_edit_channels
      </BIcon>
    </template>
    <div class="reminder-header">
      <div class="reminder-header-item header-title">
        {{ $t('reminder.todayActions') }}
      </div>
      <div class="reminder-header-item number-of-items">
        <span>{{ todayActions.length }}件</span>
      </div>
      <div class="reminder-header-item">
        <BSelect
          v-model="selectedActionType"
          class="type-select"
          :items="nextActionTypeItems"
          item-text="text"
          item-value="value"
          filterable
          :teleported="false"
        />
      </div>
      <div class="reminder-header-item">
        <BSelect
          v-model="selectedUser"
          class="owner-select"
          :items="userItems"
          item-text="text"
          item-value="value"
          filterable
          :teleported="false"
        />
      </div>
      <div class="reminder-header-item">
        <BPrioritySelect
          v-model="selectedPriority"
          class="priority-select"
          needs-all-option
          :teleported="false"
        />
      </div>
    </div>
    <div class="reminder-body">
      <ReminderTimeline
        v-if="isToday"
        :actions="todayActions"
        :fetch-action="getReminderAndNextActionType"
      />
    </div>
    <div class="reminder-footer">
      <BBtn
        class="footer-item"
        text
        @click="showLeadsHasPastAction"
      >
        {{ $t('reminder.pastActions') }}
      </BBtn>
      <BBtn
        class="footer-item"
        text
        @click="showLeadsHasFutureAction"
      >
        {{ $t('reminder.futureActions') }}
      </BBtn>
    </div>
  </BIconWithActionMenu>
</template>

<script lang="ts">
import lodash from 'lodash';
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { useUsers } from '@/composable/user/user/users';
import filterService from '@/mixins/filter_service/index';
import queryParameter from '@/mixins/query_parameter';
import { endOf, add, startOf, newDate, format, formatUtcISO8601String } from '@/utils/date-time';

export default defineComponent({
  mixins: [queryParameter, filterService],
  setup() {
    const ACTION_TYPE_SELECTOR_VALUE_ALL = 'all';
    const OWNER_SELECTOR_VALUE_ALL = 'all';
    const { activeUsers, fetchUsers } = useUsers();
    return {
      ACTION_TYPE_SELECTOR_VALUE_ALL,
      OWNER_SELECTOR_VALUE_ALL,
      activeUsers,
      fetchUsers,
    };
  },
  data() {
    return {
      isToday: true,
      selectedActionType: this.ACTION_TYPE_SELECTOR_VALUE_ALL,
      selectedUser: this.OWNER_SELECTOR_VALUE_ALL,
      selectedPriority: null,
    };
  },
  computed: {
    ...mapGetters('user', [
      'defaultNextActionTypesSelectableByUser',
      'defaultNextActionTypesNotSelectableByUser',
      'nextActionTypes',
      'todayActions',
      'currentUser',
    ]),
    ...mapGetters('userUi', ['leadListFilterParams', 'leadListPageParams', 'isDisabledReminderClose']),
    nextActionTypeItems() {
      // TODO: src/api/user/models/next_action_types.tsに実装を統一する
      return [{ text: this.$t('reminder.allActionType'), value: this.ACTION_TYPE_SELECTOR_VALUE_ALL }]
        .concat(
          this.defaultNextActionTypesSelectableByUser.map((candidate) => {
            return { text: candidate.name, value: `default${candidate.id}` };
          }),
        )
        .concat(
          this.nextActionTypes.map((candidate) => {
            return { text: candidate.name, value: String(candidate.id) };
          }),
        );
    },
    userItems() {
      const userItems = [{ text: this.$t('reminder.allOwner'), value: this.OWNER_SELECTOR_VALUE_ALL }];
      return userItems.concat(
        this.activeUsers.map((candidate) => {
          return { text: candidate.name, value: String(candidate.id) };
        }),
      );
    },
    selectedUserId() {
      return this.selectedUser === this.OWNER_SELECTOR_VALUE_ALL ? null : this.selectedUser;
    },
    selectedActionTypeId() {
      return this.selectedActionType === this.ACTION_TYPE_SELECTOR_VALUE_ALL ? null : this.selectedActionType;
    },
  },
  watch: {
    selectedActionType () {
      this.fetchReminder();
    },
    selectedUser () {
      this.fetchReminder();
    },
    selectedPriority () {
      this.fetchReminder();
    },
  },
  beforeCreate() {
    this.$options.computed = {
      ...mapGetters('user', ['reminder']),
      ...this.$options.computed,
    };
  },
  async created() {
    await this.fetchMasterData();
    this.selectedUser = `${this.currentUser.id}`;
    this.fetchReminder();
  },
  methods: {
    ...mapWaitingActions('user', {
      getReminderAction: 'getReminderWait',
      getNextActionTypesAction: 'getNextActionTypesWait',
      getCallTargetsAction: 'getCallTargetsActionWait',
      getCurrentUserAction: 'getCurrentUserWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
    }),
    handleAfterOpen() {
      this.fetchReminder();
      this.fetchMasterData();
    },
    async fetchMasterData() {
      await Promise.all([this.getCurrentUserAction(), this.getNextActionTypesAction(), this.fetchUsers()]);
    },
    fetchReminder() {
      const startFrom = format(startOf(newDate(), 'day'));
      const ownerId = this.selectedUserId;
      const priority = this.selectedPriority;
      const defaultActionTypeId = this.selectedActionTypeId?.match(/^default(?<id>\d+)$/)?.groups?.id || '';
      const actionTypeId = this.selectedActionTypeId?.match(/^(?<id>\d+)$/)?.groups?.id || '';
      this.getReminderAction({ params: { startFrom, ownerId, priority, actionTypeId, defaultActionTypeId } });
    },
    showLeadsHasPastAction() {
      this.close();
      const date = add(newDate(), -1, 'days');
      const fromDate = add(startOf(date, 'day'), -6, 'months');
      const toDate = endOf(date, 'day');
      this.setQueryAndMovePage(fromDate, toDate);
    },
    showLeadsHasFutureAction() {
      this.close();
      const date = add(newDate(), 1, 'days');
      const fromDate = startOf(date, 'day');
      const toDate = add(endOf(date, 'day'), 6, 'months');
      this.setQueryAndMovePage(fromDate, toDate);
    },
    setQueryAndMovePage(fromDate, toDate) {
      const dateRange = [formatUtcISO8601String(fromDate), formatUtcISO8601String(toDate)];

      const filterParams = {
        nextActionsReservedAt: { inc: { or: dateRange } },
        nextActionsActionType: { inc: { or: this.selectedActionTypeId ? [this.selectedActionType] : [] } },
        nextActionsOwner: { inc: { or: this.selectedUserId ? [this.selectedUser] : [] } },
        nextActionsCompletedPresent: { inc: { or: false } },
        nextActionsPriority: { inc: { or: this.selectedPriority? [this.selectedPriority] : [] } },
      };

      if (this.selectedActionType === '') {
        delete filterParams.nextActionsActionType;
      }
      if (this.selectedUser === '') {
        delete filterParams.nextActionsOwner;
      }

      if (this.$route.name !== 'LeadList') {
        this.$router.push({ name: 'LeadList' });
      }
      this.setLeadListFilterParamsAction(filterParams);
      // 現在の表示数設定で1ページ目を表示する
      this.setLeadListPageParamsAction(Object.assign(lodash.cloneDeep(this.leadListPageParams), { currentPage: 1 }));
      this.getCallTargetsAction();
      this.setFilterAndMoveLeadList(filterParams);
    },
    close() {
      this.$refs.bIconWithActionMenuContainer?.close();
    },
  },
});
</script>

<style lang="scss" scoped>
  .reminder-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid $bdcolor-base;
    padding: 0 $basespace-200;

    &-item {
      margin: $basespace-250 0;

      &.header-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 0;
      }

      &.number-of-items {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin: $basespace-500 0;

        span {
          display:inline-block;
          vertical-align: middle;
          text-align: center;
          width: 54px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &-select {
        height: 36px;
      }

      .type-select {
        width: 150px;
      }

      .owner-select {
        width: 150px;
      }

      .priority-select {
        width: 160px;
      }
    }
  }

  .reminder-body {
    @include m-fixed-height(510px);
    overflow-y: auto;
  }

  .reminder-title {
    text-align: center;
    font-size: $fontsize-300;
    font-weight: 600;
    margin-bottom: $basespace-200;
  }
  .reminder-switching {
    height: 40px;
  }

  .side-badge {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    outline: 0;
  }
  .badge-color-on {
    color: $textcolor-white;
    background-color: $basecolor-primary;
  }
  .badge-color-off {
    color: $basecolor-primary;
    background-color: $bgcolor-base;
  }

  .reminder-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: space-around;
    background: $bgcolor-base;

    .footer-item {
      line-height: 60px;
    }
  }

  :deep(.card-body) {
    height: 640px;
  }
</style>

/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetActionNotifications200Response } from '../models';
// @ts-ignore
import { GetAnnouncements200Response } from '../models';
// @ts-ignore
import { GetUnreadNotificationsCount200Response } from '../models';
// @ts-ignore
import { ReadType } from '../models';
/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 通知一覧取得API
         * @summary 通知一覧取得API
         * @param {ReadType} [readType] 既読かどうか
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionNotifications: async (readType?: ReadType, currentPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/notifications/action_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (readType !== undefined) {
                localVarQueryParameter['readType'] = readType;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせ一覧取得API
         * @summary お知らせ一覧取得API
         * @param {ReadType} [readType] 既読かどうか
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncements: async (readType?: ReadType, currentPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/notifications/announcements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (readType !== undefined) {
                localVarQueryParameter['readType'] = readType;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 未読通知数取得API
         * @summary 未読通知数取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/notifications/unread/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通知既読API
         * @summary 通知既読API
         * @param {number} actionNotificationId 通知ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActionNotificationRead: async (actionNotificationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionNotificationId' is not null or undefined
            assertParamExists('postActionNotificationRead', 'actionNotificationId', actionNotificationId)
            const localVarPath = `/api/user/notifications/action_notifications/{actionNotificationId}/read`
                .replace(`{${"actionNotificationId"}}`, encodeURIComponent(String(actionNotificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせ既読API
         * @summary お知らせ既読API
         * @param {number} announcementId お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnnouncementRead: async (announcementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'announcementId' is not null or undefined
            assertParamExists('postAnnouncementRead', 'announcementId', announcementId)
            const localVarPath = `/api/user/notifications/announcements/{announcementId}/read`
                .replace(`{${"announcementId"}}`, encodeURIComponent(String(announcementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 通知一覧取得API
         * @summary 通知一覧取得API
         * @param {ReadType} [readType] 既読かどうか
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionNotifications(readType?: ReadType, currentPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetActionNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionNotifications(readType, currentPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせ一覧取得API
         * @summary お知らせ一覧取得API
         * @param {ReadType} [readType] 既読かどうか
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncements(readType?: ReadType, currentPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnnouncements200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncements(readType, currentPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 未読通知数取得API
         * @summary 未読通知数取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadNotificationsCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnreadNotificationsCount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadNotificationsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 通知既読API
         * @summary 通知既読API
         * @param {number} actionNotificationId 通知ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActionNotificationRead(actionNotificationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActionNotificationRead(actionNotificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせ既読API
         * @summary お知らせ既読API
         * @param {number} announcementId お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAnnouncementRead(announcementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAnnouncementRead(announcementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 通知一覧取得API
         * @summary 通知一覧取得API
         * @param {ReadType} [readType] 既読かどうか
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionNotifications(readType?: ReadType, currentPage?: number, options?: any): AxiosPromise<GetActionNotifications200Response> {
            return localVarFp.getActionNotifications(readType, currentPage, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせ一覧取得API
         * @summary お知らせ一覧取得API
         * @param {ReadType} [readType] 既読かどうか
         * @param {number} [currentPage] リードリストでの現在のページネーション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncements(readType?: ReadType, currentPage?: number, options?: any): AxiosPromise<GetAnnouncements200Response> {
            return localVarFp.getAnnouncements(readType, currentPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 未読通知数取得API
         * @summary 未読通知数取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadNotificationsCount(options?: any): AxiosPromise<GetUnreadNotificationsCount200Response> {
            return localVarFp.getUnreadNotificationsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 通知既読API
         * @summary 通知既読API
         * @param {number} actionNotificationId 通知ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActionNotificationRead(actionNotificationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postActionNotificationRead(actionNotificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせ既読API
         * @summary お知らせ既読API
         * @param {number} announcementId お知らせID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnnouncementRead(announcementId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postAnnouncementRead(announcementId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - interface
 * @export
 * @interface NotificationApi
 */
export interface NotificationApiInterface {
    /**
     * 通知一覧取得API
     * @summary 通知一覧取得API
     * @param {ReadType} [readType] 既読かどうか
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    getActionNotifications(readType?: ReadType, currentPage?: number, options?: AxiosRequestConfig): AxiosPromise<GetActionNotifications200Response>;

    /**
     * お知らせ一覧取得API
     * @summary お知らせ一覧取得API
     * @param {ReadType} [readType] 既読かどうか
     * @param {number} [currentPage] リードリストでの現在のページネーション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    getAnnouncements(readType?: ReadType, currentPage?: number, options?: AxiosRequestConfig): AxiosPromise<GetAnnouncements200Response>;

    /**
     * 未読通知数取得API
     * @summary 未読通知数取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    getUnreadNotificationsCount(options?: AxiosRequestConfig): AxiosPromise<GetUnreadNotificationsCount200Response>;

    /**
     * 通知既読API
     * @summary 通知既読API
     * @param {number} actionNotificationId 通知ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    postActionNotificationRead(actionNotificationId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * お知らせ既読API
     * @summary お知らせ既読API
     * @param {number} announcementId お知らせID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiInterface
     */
    postAnnouncementRead(announcementId: number, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for getActionNotifications operation in NotificationApi.
 * @export
 * @interface NotificationApiGetActionNotificationsRequest
 */
export interface NotificationApiGetActionNotificationsRequest {
    /**
     * 既読かどうか
     * @type {ReadType}
     * @memberof NotificationApiGetActionNotifications
     */
    readonly readType?: ReadType

    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof NotificationApiGetActionNotifications
     */
    readonly currentPage?: number
}

/**
 * Request parameters for getAnnouncements operation in NotificationApi.
 * @export
 * @interface NotificationApiGetAnnouncementsRequest
 */
export interface NotificationApiGetAnnouncementsRequest {
    /**
     * 既読かどうか
     * @type {ReadType}
     * @memberof NotificationApiGetAnnouncements
     */
    readonly readType?: ReadType

    /**
     * リードリストでの現在のページネーション
     * @type {number}
     * @memberof NotificationApiGetAnnouncements
     */
    readonly currentPage?: number
}

/**
 * Request parameters for postActionNotificationRead operation in NotificationApi.
 * @export
 * @interface NotificationApiPostActionNotificationReadRequest
 */
export interface NotificationApiPostActionNotificationReadRequest {
    /**
     * 通知ID
     * @type {number}
     * @memberof NotificationApiPostActionNotificationRead
     */
    readonly actionNotificationId: number
}

/**
 * Request parameters for postAnnouncementRead operation in NotificationApi.
 * @export
 * @interface NotificationApiPostAnnouncementReadRequest
 */
export interface NotificationApiPostAnnouncementReadRequest {
    /**
     * お知らせID
     * @type {number}
     * @memberof NotificationApiPostAnnouncementRead
     */
    readonly announcementId: number
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI implements NotificationApiInterface {
    /**
     * 通知一覧取得API
     * @summary 通知一覧取得API
     * @param {NotificationApiGetActionNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getActionNotifications(requestParameters: NotificationApiGetActionNotificationsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getActionNotifications(requestParameters.readType, requestParameters.currentPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせ一覧取得API
     * @summary お知らせ一覧取得API
     * @param {NotificationApiGetAnnouncementsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getAnnouncements(requestParameters: NotificationApiGetAnnouncementsRequest = {}, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getAnnouncements(requestParameters.readType, requestParameters.currentPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 未読通知数取得API
     * @summary 未読通知数取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getUnreadNotificationsCount(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getUnreadNotificationsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通知既読API
     * @summary 通知既読API
     * @param {NotificationApiPostActionNotificationReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public postActionNotificationRead(requestParameters: NotificationApiPostActionNotificationReadRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).postActionNotificationRead(requestParameters.actionNotificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせ既読API
     * @summary お知らせ既読API
     * @param {NotificationApiPostAnnouncementReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public postAnnouncementRead(requestParameters: NotificationApiPostAnnouncementReadRequest, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).postAnnouncementRead(requestParameters.announcementId, options).then((request) => request(this.axios, this.basePath));
    }
}

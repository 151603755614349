import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { GetMailNotificationCount200Response, GetMailNotifications200Response, MailNotificationApi, MailNotificationApiGetMailNotificationCountRequest, MailNotificationApiGetMailNotificationsRequest } from '@/api/openapi';

export class MailNotificationApiService extends ApiService {
  api = new MailNotificationApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getMailNotifications({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailNotificationApiGetMailNotificationsRequest>): Promise<AxiosResponse<GetMailNotifications200Response>> {
    return this.checkResponse<GetMailNotifications200Response>(
      this.api.getMailNotifications(request, options),
      errorHandlers,
    );
  }

  async postMailNotificationConfirmed({
    options,
    errorHandlers,
  }: ApiArguments<never> = {}): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.postMailNotificationConfirmed(options),
      errorHandlers,
    );
  }

  async getMailNotificationCount({
    request,
    options,
    errorHandlers,
  }: ApiArguments<MailNotificationApiGetMailNotificationCountRequest>): Promise<AxiosResponse<GetMailNotificationCount200Response>> {
    return this.checkResponse<GetMailNotificationCount200Response>(
      this.api.getMailNotificationCount(request, options),
      errorHandlers,
    );
  }
}


export const types = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_ADMIN: 'SET_ADMIN',
  SET_ADMINS: 'SET_ADMINS',
  SET_USER: 'SET_USER',
  SET_USERS: 'SET_USERS',
  SET_CLIENT: 'SET_CLIENT',
  SET_CLIENTS: 'SET_CLIENTS',
  SET_FREE_TAGS: 'SET_FREE_TAGS',
  SET_SF_CAMPAIGNS: 'SET_SF_CAMPAIGNS',
  SET_LEAD_CSV_IMPORT_TASK: 'SET_LEAD_CSV_IMPORT_TASK',
  SET_LEAD_CSV_IMPORT_TASKS: 'SET_LEAD_CSV_IMPORT_TASKS',
  SET_LEAD_EXCEL_IMPORT_TASK: 'SET_LEAD_EXCEL_IMPORT_TASK',
  SET_LEAD_EXCEL_IMPORT_TASKS: 'SET_LEAD_EXCEL_IMPORT_TASKS',
  SET_LEAD_CSV_OVERWRITE_TASK: 'SET_LEAD_CSV_OVERWRITE_TASK',
  SET_LEAD_CSV_OVERWRITE_TASKS: 'SET_LEAD_CSV_OVERWRITE_TASKS',
  SET_LEAD_EXCEL_OVERWRITE_TASK: 'SET_LEAD_EXCEL_OVERWRITE_TASK',
  SET_LEAD_EXCEL_OVERWRITE_TASKS: 'SET_LEAD_EXCEL_OVERWRITE_TASKS',
  SET_LEAD_EXPORT_TASKS: 'SET_LEAD_EXPORT_TASKS',
  SET_LEAD_DELETE_TASKS: 'SET_LEAD_DELETE_TASKS',
  SET_SF_LEAD_DATA_CHECK_TASKS: 'SET_SF_LEAD_DATA_CHECK_TASKS',
  SET_CUSTOM_FIELDS: 'SET_CUSTOM_FIELDS',
  SET_CALL_TARGET: 'SET_CALL_TARGET',
  SET_CALL_TARGET_PREVIOUS_AND_NEXT_ID: 'SET_CALL_TARGET_PREVIOUS_AND_NEXT_ID',
  SET_CALL_TARGETS: 'SET_CALL_TARGETS',
  SET_CALL_TARGETS_META: 'SET_CALL_TARGETS_META',
  SET_FULLTEXT_SEARCH_RESULT: 'SET_FULLTEXT_SEARCH_RESULT',
  SET_CALL_TARGET_LEAD_STAGE: 'SET_CALL_TARGET_LEAD_STAGE',
  SET_CALL_TARGET_FREE_TAGS: 'SET_CALL_TARGET_FREE_TAGS',
  SET_CALL_TARGET_NOTES: 'SET_CALL_TARGET_NOTES',
  SET_CALL_RESULT_TYPES: 'SET_CALL_RESULT_TYPES',
  SET_DEFAULT_CALL_RESULT_TYPES: 'SET_DEFAULT_CALL_RESULT_TYPES',
  SET_NEXT_ACTIONS: 'SET_NEXT_ACTIONS',
  SET_NEXT_ACTION_TYPES: 'SET_NEXT_ACTION_TYPES',
  SET_DEFAULT_NEXT_ACTION_TYPES: 'SET_DEFAULT_NEXT_ACTION_TYPES',
  SET_REMINDER: 'SET_REMINDER',
  SET_MAIL_TEMPLATES: 'SET_MAIL_TEMPLATES',
  SET_MAIL_TEMPLATE: 'SET_MAIL_TEMPLATE',
  CLEAR_MAIL_TEMPLATES: 'CLEAR_MAIL_TEMPLATES',
  CLEAR_MAIL_TEMPLATE: 'CLEAR_MAIL_TEMPLATE',
  SET_MIITEL_AUTH: 'SET_MIITEL_AUTH',
  CLEAR_MIITEL_AUTH: 'CLEAR_MIITEL_AUTH',
  SET_CALL_ANALYTICS: 'SET_CALL_ANALYTICS',
  SET_ALL_USER_CALL_ANALYTICS: 'SET_ALL_USER_CALL_ANALYTICS',
  SET_CALL_RESULT_CALL_ANALYTICS: 'SET_CALL_RESULT_CALL_ANALYTICS',
  SET_REVISION_ID: 'SET_REVISION_ID',
  SET_BUSINESS_CATEGORY_CANDIDATES: 'SET_BUSINESS_CATEGORY_CANDIDATES',
  SET_POSITION_CANDIDATES: 'SET_POSITION_CANDIDATES',
  SET_DEPARTMENT_CANDIDATES: 'SET_DEPARTMENT_CANDIDATES',
  SET_ASSIGNED_USER_CANDIDATES: 'SET_ASSIGNED_USER_CANDIDATES',
  SET_LEAD_VIEWS: 'SET_LEAD_VIEWS',
  SET_LEAD_VIEW: 'SET_LEAD_VIEW',
  SET_CALL_TARGET_UNSUBSCRIBE: 'SET_CALL_TARGET_UNSUBSCRIBE',
  SET_MAIL_LEAD_COUNT: 'SET_MAIL_LEAD_COUNT',
  SET_BULK_MAILS: 'SET_BULK_MAILS',
  SET_BULK_MAILS_META: 'SET_BULK_MAILS_META',
  SET_BULK_MAIL: 'SET_BULK_MAIL',
  SET_BULK_MAIL_CAMPAIGN_NAMES: 'SET_BULK_MAIL_CAMPAIGN_NAMES',
  SET_AVAILABLE_FEATURES: 'SET_AVAILABLE_FEATURES',
  SET_SENDGRID_EVENTS: 'SET_SENDGRID_EVENTS',
  SET_CALL_TARGETS_COUNT: 'SET_CALL_TARGETS_COUNT',
  SET_CALL_TARGET_OPERATOR: 'SET_CALL_TARGET_OPERATOR',
  SET_SALESFORCE_SETTING: 'SET_SALESFORCE_SETTING',
  SET_SF_USERS: 'SET_SF_USERS',
  SET_BOXIL_LEAD_IMPORT_SETTING: 'SET_BOXIL_LEAD_IMPORT_SETTING',
  CLEAR_CURRENT_USER: 'CLEAR_CURRENT_USER',
  CLEAR_ADMIN: 'CLEAR_ADMIN',
  CLEAR_ADMINS: 'CLEAR_ADMINS',
  CLEAR_USER: 'CLEAR_USER',
  CLEAR_USERS: 'CLEAR_USERS',
  CLEAR_CLIENT: 'CLEAR_CLIENT',
  CLEAR_CLIENTS: 'CLEAR_CLIENTS',
  CLEAR_FREE_TAGS: 'CLEAR_FREE_TAGS',
  CLEAR_SF_CAMPAIGNS: 'CLEAR_SF_CAMPAIGNS',
  CLEAR_CALL_LIST: 'CLEAR_CALL_LIST',
  CLEAR_CALL_LISTS: 'CLEAR_CALL_LISTS',
  CLEAR_CALL_TARGET: 'CLEAR_CALL_TARGET',
  CLEAR_CALL_TARGETS: 'CLEAR_CALL_TARGETS',
  CLEAR_CALL_TARGETS_META: 'CLEAR_CALL_TARGETS_META',
  CLEAR_FULLTEXT_SEARCH_RESULT: 'CLEAR_FULLTEXT_SEARCH_RESULT',
  CLEAR_CALL_TARGET_FREE_TAGS: 'CLEAR_CALL_TARGET_FREE_TAGS',
  CLEAR_CALL_TARGET_NOTES: 'CLEAR_CALL_TARGET_NOTES',
  CLEAR_CALL_RESULT_TYPES: 'CLEAR_CALL_RESULT_TYPES',
  CLEAR_DEFAULT_CALL_RESULT_TYPES: 'CLEAR_DEFAULT_CALL_RESULT_TYPES',
  CLEAR_NEXT_ACTIONS: 'CLEAR_NEXT_ACTIONS',
  CLEAR_NEXT_ACTION_TYPES: 'CLEAR_NEXT_ACTION_TYPES',
  CLEAR_DEFAULT_NEXT_ACTION_TYPES: 'CLEAR_DEFAULT_NEXT_ACTION_TYPES',
  CLEAR_REMINDER: 'CLEAR_REMINDER',
  CLEAR_CALL_ANALYTICS: 'CLEAR_CALL_ANALYTICS',
  CLEAR_REVISION_ID: 'CREAR_REVISION_ID',
  SET_LEAD_SOURCES: 'SET_LEAD_SOURCES',
  SET_LEAD_STAGES: 'SET_LEAD_STAGES',
  SET_CALL_TARGET_LEAD_SOURCES: 'SET_CALL_TARGET_LEAD_SOURCES',
  SET_LEAD_HISTORIES: 'SET_LEAD_HISTORIES',
  CLEAR_LEAD_SOURCES: 'CLEAR_LEAD_SOURCES',
  CLEAR_LEAD_STAGES: 'CLEAR_LEAD_STAGES',
  CLEAR_CALL_TARGET_LEAD_SOURCES: 'CLEAR_CALL_TARGET_LEAD_SOURCES',
  CLEAR_LEAD_HISTORIES: 'CLEAR_LEAD_HISTORIES',
  CLEAR_LEAD_EXPORT_TASKS: 'CLEAR_LEAD_EXPORT_TASKS',
  CLEAR_BUSINESS_CATEGORY_CANDIDATES: 'CLEAR_BUSINESS_CATEGORY_CANDIDATES',
  CLEAR_POSITION_CANDIDATES: 'CLEAR_POSITION_CANDIDATES',
  CLEAR_DEPARTMENT_CANDIDATES: 'CLEAR_DEPARTMENT_CANDIDATES',
  CLEAR_ASSIGNED_USER_CANDIDATES: 'CLEAR_ASSIGNED_USER_CANDIDATES',
  CLEAR_LEAD_VIEW: 'CLEAR_LEAD_VIEW',
  CLEAR_CALL_TARGET_UNSUBSCRIBE: 'CLEAR_CALL_TARGET_UNSUBSCRIBE',
  CLEAR_BULK_MAILS: 'CLEAR_BULK_MAILS',
  CLEAR_BULK_MAILS_META: 'CLEAR_BULK_MAILS_META',
  CLEAR_CALL_TARGETS_COUNT: 'CLEAR_CALL_TARGETS_COUNT',
  CLEAR_BULK_MAIL: 'CLEAR_BULK_MAIL',
  CLEAR_BULK_MAIL_CAMPAIGN_NAMES: 'CLEAR_BULK_MAIL_CAMPAIGN_NAMES',
  CLEAR_CALL_TARGET_OPERATOR: 'CLEAR_CALL_TARGET_OPERATOR',
  CLEAR_SALESFORCE_SETTING: 'CLEAR_SALESFORCE_SETTING',
  CLEAR_BOXIL_LEAD_IMPORT_SETTING: 'CLEAR_BOXIL_LEAD_IMPORT_SETTING',
  SET_LEAD_SF_IMPORT_TASK: 'SET_LEAD_SF_IMPORT_TASK',
  SET_LEAD_SF_IMPORT_TASKS: 'SET_LEAD_SF_IMPORT_TASKS',
  SET_SF_STATUSES: 'SET_SF_STATUSES',
  CLEAR_SF_STATUSES: 'CLEAR_SF_STATUSES',
  SET_SF_AVAILABLE_MAPPING_FIELDS: 'SET_SF_AVAILABLE_MAPPING_FIELDS',
  CLEAR_SF_AVAILABLE_MAPPING_FIELDS: 'CLEAR_SF_AVAILABLE_MAPPING_FIELDS',
};

const set = {
  [types.SET_CURRENT_USER](state, data) {
    state.currentUser = data;
  },
  [types.SET_ADMIN](state, data) {
    state.admin = data;
  },
  [types.SET_ADMINS](state, data) {
    state.admins = data;
  },
  [types.SET_USER](state, data) {
    state.user = data;
  },
  [types.SET_USERS](state, data) {
    state.users = data;
  },
  [types.SET_CONSULTANT_USERS](state, data) {
    state.consultantUsers = data;
  },
  [types.SET_CLIENT](state, data) {
    state.client = data;
  },
  [types.SET_CLIENTS](state, data) {
    state.clients = data;
  },
  [types.SET_FREE_TAGS](state, data) {
    state.freeTags = data;
  },
  [types.SET_SF_CAMPAIGNS](state, data) {
    state.sfCampaigns = data;
  },
  [types.SET_CALL_LIST](state, data) {
    state.callList = data;
  },
  [types.SET_CALL_LISTS](state, data) {
    state.callLists = data;
  },
  [types.SET_LEAD_CSV_IMPORT_TASK](state, data) {
    state.leadCsvImportTask = data;
  },
  [types.SET_LEAD_CSV_IMPORT_TASKS](state, data) {
    state.leadCsvImportTasks = data;
  },
  [types.SET_LEAD_EXCEL_IMPORT_TASK](state, data) {
    state.leadExcelImportTask = data;
  },
  [types.SET_LEAD_EXCEL_IMPORT_TASKS](state, data) {
    state.leadExcelImportTasks = data;
  },
  [types.SET_LEAD_CSV_OVERWRITE_TASK](state, data) {
    state.leadCsvOverwriteTask = data;
  },
  [types.SET_LEAD_CSV_OVERWRITE_TASKS](state, data) {
    state.leadCsvOverwriteTasks = data;
  },
  [types.SET_LEAD_EXCEL_OVERWRITE_TASK](state, data) {
    state.leadExcelOverwriteTask = data;
  },
  [types.SET_LEAD_EXCEL_OVERWRITE_TASKS](state, data) {
    state.leadExcelOverwriteTasks = data;
  },
  [types.SET_LEAD_EXPORT_TASKS](state, data) {
    state.leadExportTasks = data;
  },
  [types.SET_LEAD_DELETE_TASKS](state, data) {
    state.leadDeleteTasks = data;
  },
  [types.SET_SF_LEAD_DATA_CHECK_TASKS](state, data) {
    state.sfLeadDataCheckTasks = data;
  },
  [types.SET_CUSTOM_FIELDS](state, data) {
    state.customFields = data;
  },
  [types.SET_CALL_TARGET](state, data) {
    state.callTarget = data;
  },
  [types.SET_CALL_TARGET_PREVIOUS_AND_NEXT_ID](state, data) {
    state.callTarget.previousId = data.previousId;
    state.callTarget.nextId = data.nextId;
  },
  [types.SET_CALL_TARGETS](state, data) {
    state.callTargets = data;
  },
  [types.SET_CALL_TARGETS_META](state, data) {
    state.callTargetsMeta = data;
  },
  [types.SET_FULLTEXT_SEARCH_RESULT](state, data) {
    state.fulltextSearchResult = data;
  },
  [types.SET_CALL_TARGET_LEAD_STAGE](state, data) {
    state.callTarget.leadStage = data;
  },
  [types.SET_CALL_TARGET_FREE_TAGS](state, data) {
    state.callTargetFreeTags = data;
  },
  [types.SET_CALL_TARGET_NOTES](state, data) {
    state.callTargetNotes = data;
  },
  [types.SET_CALL_RESULT_TYPES](state, data) {
    state.callResultTypes = data;
  },
  [types.SET_DEFAULT_CALL_RESULT_TYPES](state, data) {
    state.defaultCallResultTypes = data;
  },
  [types.SET_NEXT_ACTIONS](state, data) {
    state.nextActions = data;
  },
  [types.SET_NEXT_ACTION_TYPES](state, data) {
    state.nextActionTypes = data;
  },
  [types.SET_DEFAULT_NEXT_ACTION_TYPES](state, data) {
    state.defaultNextActionTypes = data;
  },
  [types.SET_REMINDER](state, data) {
    state.reminder = data;
  },
  [types.SET_MAIL_TEMPLATE](state, data) {
    state.mailTemplate = data;
  },
  [types.SET_MAIL_TEMPLATES](state, data) {
    state.mailTemplates = data;
  },
  [types.SET_MIITEL_AUTH](state, data) {
    state.miitelAuth = data;
  },
  [types.SET_CALL_ANALYTICS](state, data) {
    state.callAnalytics = data;
  },
  [types.SET_ALL_USER_CALL_ANALYTICS](state, data) {
    state.allUserCallAnalytics = data;
  },
  [types.SET_CALL_RESULT_CALL_ANALYTICS](state, data) {
    state.callResultCallAnalytics = data;
  },
  [types.SET_REVISION_ID](state, data) {
    state.revisionId = data;
  },
  [types.SET_LEAD_SOURCES](state, data) {
    state.leadSources = data;
  },
  [types.SET_LEAD_STAGES](state, data) {
    state.leadStages = data;
  },
  [types.SET_CALL_TARGET_LEAD_SOURCES](state, data) {
    state.callTargetLeadSources = data;
  },
  [types.SET_LEAD_HISTORIES](state, data) {
    state.leadHistories = data;
  },
  [types.SET_BUSINESS_CATEGORY_CANDIDATES](state, data) {
    state.businessCategoryCandidates = data;
  },
  [types.SET_POSITION_CANDIDATES](state, data) {
    state.positionCandidates = data;
  },
  [types.SET_DEPARTMENT_CANDIDATES](state, data) {
    state.departmentCandidates = data;
  },
  [types.SET_ASSIGNED_USER_CANDIDATES](state, data) {
    state.assignedUserCandidates = data;
  },
  [types.SET_LEAD_VIEWS](state, data) {
    state.leadViews = data;
  },
  [types.SET_LEAD_VIEW](state, data) {
    state.leadView = data;
  },
  [types.SET_CALL_TARGET_UNSUBSCRIBE](state, data) {
    state.callTargetUnsubscribe = data;
  },
  [types.SET_MAIL_LEAD_COUNT](state, data) {
    state.mailLeadCount = data;
  },
  [types.SET_BULK_MAILS](state, data) {
    state.bulkMails = data;
  },
  [types.SET_BULK_MAILS_META](state, data) {
    state.bulkMailsMeta = data;
  },
  [types.SET_BULK_MAIL](state, data) {
    state.bulkMail = data;
  },
  [types.SET_BULK_MAIL_CAMPAIGN_NAMES](state, data) {
    state.bulkMailCampaignNames = data;
  },
  [types.SET_AVAILABLE_FEATURES](state, data) {
    state.availableFeatures = data;
  },
  [types.SET_SENDGRID_EVENTS](state, data) {
    state.sendgridEvents = data;
  },
  [types.SET_CALL_TARGETS_COUNT](state, data) {
    state.callTargetsCount = data;
  },
  [types.SET_CALL_TARGET_OPERATOR](state, data) {
    state.operator = data;
  },
  [types.SET_SALESFORCE_SETTING](state, data) {
    state.salesforceSetting = data;
  },
  [types.SET_SF_USERS](state, data) {
    state.sfUsers = data;
  },
  [types.SET_BOXIL_LEAD_IMPORT_SETTING](state, data) {
    state.boxilLeadImportSetting = data;
  },
  [types.SET_LEAD_SF_IMPORT_TASK](state, data) {
    state.sfReportImportTask = data;
  },
  [types.SET_LEAD_SF_IMPORT_TASKS](state, data) {
    state.sfReportImportTasks = data;
  },
  [types.SET_SF_STATUSES](state, data) {
    state.sfStatuses = data;
  },
  [types.SET_SF_AVAILABLE_MAPPING_FIELDS](state, data) {
    state.sfAvailableMappingFields = data;
  },
};

const clear = {
  [types.CLEAR_CURRENT_USER](state) {
    state.currentUser = {};
  },
  [types.CLEAR_ADMIN](state) {
    state.admin = {};
  },
  [types.CLEAR_ADMINS](state) {
    state.admins = [];
  },
  [types.CLEAR_USER](state) {
    state.user = {};
  },
  [types.CLEAR_USERS](state) {
    state.users = [];
  },
  [types.CLEAR_CLIENT](state) {
    state.client = {};
  },
  [types.CLEAR_CLIENTS](state) {
    state.clients = [];
  },
  [types.CLEAR_FREE_TAGS](state) {
    state.freeTags = [];
  },
  [types.CLEAR_SF_CAMPAIGNS](state) {
    state.sfCampaigns = [];
  },
  [types.CLEAR_CALL_TARGET](state) {
    state.callTarget = {};
    state.callTargetFreeTags = [];
    state.callTargetLeadSources = [];
    state.leadHistories = [];
    state.nextActions = [];
    state.callTargetNotes = [];
    state.operator = {};
    state.callTargetUnsubscribe = {};
  },
  [types.CLEAR_CALL_TARGETS](state) {
    state.callTargets = [];
  },
  [types.CLEAR_CALL_TARGETS_META](state) {
    state.callTargetsMeta = {};
  },
  [types.CLEAR_FULLTEXT_SEARCH_RESULT](state, data) {
    state.fulltextSearchResult = [];
  },
  [types.CLEAR_CALL_TARGET_FREE_TAGS](state) {
    state.callTargetFreeTags = [];
  },
  [types.CLEAR_CALL_TARGET_NOTES](state) {
    state.callTargetNotes = [];
  },
  [types.CLEAR_CALL_RESULT_TYPES](state) {
    state.callResultTypes = [];
  },
  [types.CLEAR_DEFAULT_CALL_RESULT_TYPES](state) {
    state.defaultCallResultTypes = [];
  },
  [types.CLEAR_NEXT_ACTIONS](state) {
    state.nextActions = [];
  },
  [types.CLEAR_NEXT_ACTION_TYPES](state) {
    state.nextActionTypes = [];
  },
  [types.CLEAR_DEFAULT_NEXT_ACTION_TYPES](state) {
    state.defaultNextActionTypes = [];
  },
  [types.CLEAR_OPPORTUNITIES](state) {
    state.opportunities = [];
  },
  [types.CLEAR_REMINDER](state) {
    state.reminder = {};
  },
  [types.CLEAR_MIITEL_AUTH](state) {
    state.miitelAuth = {
      companyId: null,
      accessKey: null,
    };
  },
  [types.CLEAR_CALL_ANALYTICS](state) {
    state.callAnalytics = [];
  },
  [types.CLEAR_REVISION_ID](state) {
    state.revisionId = 0;
  },
  [types.CLEAR_LEAD_SOURCES](state) {
    state.leadSources = [];
  },
  [types.CLEAR_LEAD_STAGES](state) {
    state.leadStages = [];
  },
  [types.CLEAR_CALL_TARGET_LEAD_SOURCES](state) {
    state.callTargetLeadSources = [];
  },
  [types.CLEAR_LEAD_EXPORT_TASKS](state) {
    state.leadExportTasks = [];
  },
  [types.CLEAR_BUSINESS_CATEGORY_CANDIDATES](state, data) {
    state.businessCategoryCandidates = [];
  },
  [types.CLEAR_LEAD_HISTORIES](state) {
    state.leadHistories = [];
  },
  [types.CLEAR_POSITION_CANDIDATES](state) {
    state.positionCandidates = [];
  },
  [types.CLEAR_DEPARTMENT_CANDIDATES](state) {
    state.departmentCandidates = [];
  },
  [types.CLEAR_ASSIGNED_USER_CANDIDATES](state) {
    state.assignedUserCandidates = [];
  },
  [types.CLEAR_LEAD_VIEW](state) {
    state.leadView = {};
  },
  [types.CLEAR_CALL_TARGET_UNSUBSCRIBE](state) {
    state.callTargetUnsubscribe = {};
  },
  [types.CLEAR_BULK_MAILS](state) {
    state.bulkMails = [];
  },
  [types.CLEAR_BULK_MAILS_META](state) {
    state.bulkMailsMeta = {};
  },
  [types.CLEAR_CALL_TARGETS_COUNT](state) {
    state.callTargetsCount = [];
  },
  [types.CLEAR_BULK_MAIL](state) {
    state.bulkMail = {};
  },
  [types.CLEAR_BULK_MAIL_CAMPAIGN_NAMES](state) {
    state.bulkMailCampaignNames = {};
  },
  [types.CLEAR_CALL_TARGET_OPERATOR](state) {
    state.operator = {};
  },
  [types.CLEAR_SALESFORCE_SETTING](state) {
    state.salesforceSetting = {};
  },
  [types.CLEAR_BOXIL_LEAD_IMPORT_SETTING](state) {
    state.boxilLeadImportSetting = {};
  },
  [types.CLEAR_SF_STATUSES](state) {
    state.sfStatuses = [];
  },
  [types.CLEAR_MAIL_TEMPLATE](state) {
    state.mailTemplate = {};
  },
  [types.CLEAR_MAIL_TEMPLATES](state) {
    state.mailTemplates = [];
  },
  [types.CLEAR_SF_AVAILABLE_MAPPING_FIELDS](state) {
    state.sfAvailableMappingFields = [];
  },
};

export const mutations = Object.assign({}, set, clear);

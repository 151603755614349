<template>
  <div
    ref="bIconWithActionMenuContainer"
    class="b-icon-with-action-menu-container"
  >
    <BBadge
      :value="badgeCount"
      :is-dot="showDotBadge"
      :type="badgeType"
    >
      <BBtn
        fab
        flat
        size="small"
        @click="handleIconClick"
      >
        <slot name="icon" />
      </BBtn>
    </BBadge>
    <Transition name="fade-in-linear">
      <div
        v-if="isOpen"
        class="content"
        :class="placement"
      >
        <BCard
          full
        >
          <slot />
        </BCard>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onBeforeUnmount, getCurrentInstance, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { key } from '@/store/index';

type TPlacement = 'bottom-start' | 'bottom-end';
type TProps = {
  badgeCount?: number;
  width?: number;
  autoFetchInterval?: number;
  showDotBadge?: boolean;
  placement?: TPlacement;
};

type TEmit = {
  'autoFetchIntervalPassed': [];
  'afterOpen': [];
  'afterClose': [];
};

const props = withDefaults(defineProps<TProps>(), {
  width: 580,
  placement: 'bottom-start',
});
const emit = defineEmits<TEmit>();

const store = useStore(key);

const bIconWithActionMenuContainer = ref<HTMLElement | null>(null);
const isOpen = ref(false);
const badgeType = computed(() => props.showDotBadge ? 'error' : 'primary');

watch(() => isOpen.value, (newVal) => {
  if (newVal) {
    emit('afterOpen');
    document.removeEventListener('click', handleDocumentClick, true);
    document.addEventListener('click', handleDocumentClick, true);
  } else {
    emit('afterClose');
    document.removeEventListener('click', handleDocumentClick, true);
  }
});

const app = getCurrentInstance();
let timerObj: number;

onBeforeMount(() => {
  if (!props.autoFetchInterval) return;
  timerObj = app.appContext.config.globalProperties.$setInterval(() => {
    emit('autoFetchIntervalPassed');
  }, props.autoFetchInterval);
});

onBeforeUnmount(() => {
  app.appContext.config.globalProperties.$clearInterval(timerObj);
});

const handleIconClick = () => {
  isOpen.value = !isOpen.value;
};

const handleDocumentClick = (e: any) => {
  // MEMO: 個別に管理しないと、同時に開いていた場合やタイミングによって閉じたいものが閉じないことがあるかもしれないが、現状はこのケースになることはなさそうなので、個別管理はしないようにした。
  if (store.getters['userUi/isDisabledBIconWithActionMenuClose']) return;
  if (bIconWithActionMenuContainer.value?.contains(e.target)
    || !bIconWithActionMenuContainer.value
  ) return;
  close();
};

const close = () => {
  isOpen.value = false;
};

defineExpose({
  close,
});
</script>

<style lang="scss" scoped>
.b-icon-with-action-menu-container {
  position: relative;

  .content {
    position: absolute;
    top: #{$header-height + 2px};
    background: $paper;
    border: 1px solid $bdcolor-base;
    box-shadow: $boxshadow-base;
    text-align: justify;
    line-height: 1.4;
    width: v-bind('`${width}px`');
    &.bottom-end {
      right: -4px; // 視差調整
    }
  }
  .el-badge {
    // ドットの位置を調整
    :deep(.el-badge__content.is-dot) {
      right: 13px;
      top: 8px;
    }
  }
}
</style>

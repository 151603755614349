<template>
  <BIconWithActionMenu
    :width="900"
    :badge-count="mailNotificationCount"
    :auto-fetch-interval="30000"
    @auto-fetch-interval-passed="handleAutoFetchIntervalPassed"
    @after-open="handleAfterOpen"
  >
    <template #icon>
      <BIcon>email</BIcon>
    </template>
    <div class="mail-notification-header">
      <div class="mail-notification-header-item header-title">
        {{ $t('mailNotificationEvent.title') }}
      </div>
      <div class="mail-notification-header-item user-select">
        <SelectUser
          v-model="selectedUserId"
          :teleported="false"
          :use-all-label="$t('mailNotificationEvent.allUsers')"
          only-active-users
        />
      </div>
    </div>
    <div class="mail-notification-body">
      <VWait
        for="getMailNotificationsWait"
        :style="{ height: '100%' }"
      >
        <template #waiting>
          <BPlaceholder
            v-loading="true"
            height="50vh"
            element-loading-background="transparent"
          />
        </template>
        <MailNotificationTimeline
          :v-loading="$wait.is('getMailNotificationAction')"
          :items="mailNotifications"
        />
      </VWait>
    </div>
  </BIconWithActionMenu>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref, watch } from 'vue';
import { MailNotification } from '@/api/openapi';
import { MailNotificationApiService } from '@/api/user/resources/mail_notification';
import MailNotificationTimeline from '@/components/organisms/user/mailNotification/MailNotificationTimeline.vue';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import SelectUser from '@/plugins/biscuet-materials/components/molecules/SelectUser.vue';

const { currentUser, fetchCurrentUser } = useCurrentUser();
const { doActionWithWait } = useWait();
const api = new MailNotificationApiService();

const selectedUserId = ref<number | null>(null);
const mailNotificationCount = ref<number>(0);
const mailNotifications = ref<MailNotification[]>([]);
const request = computed(() => ({
  sentUserId: selectedUserId.value,
}));

onBeforeMount(async () => {
  await setDefaultSelectedUserId();
  await fetchMailNotificationCount();
});
watch(selectedUserId, async () => {
  await fetchMailNotificationCount();
  await loadMailNotifications();
});

const setDefaultSelectedUserId = async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
};
const loadMailNotifications = async () => {
  await fetchMailNotifications();
  await postMailNotificationConfirmed();
};

const handleAfterOpen = async () => {
  await loadMailNotifications();
};
const handleAutoFetchIntervalPassed = async () => {
  await fetchMailNotificationCount();
};

const fetchMailNotifications = async () => {
  await doActionWithWait('getMailNotificationsWait', async () => {
    const { data } = await api.getMailNotifications({
      request: request.value,
    });
    mailNotifications.value = data.mailNotifications;
  });
};
const fetchMailNotificationCount = async () => {
  const { data } = await api.getMailNotificationCount({
    request: request.value,
  });
  mailNotificationCount.value = data.mailNotificationCount;
};
const postMailNotificationConfirmed = async () => {
  if (selectedUserId.value !== currentUser.value.id) return;
  await api.postMailNotificationConfirmed();
};
</script>

<style lang="scss" scoped>
.mail-notification-header {
  display: flex;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid $bdcolor-base;
  padding: 0 $basespace-200;

  &-item {
    @include m-flex-align-center;

    &.header-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .user-select {
    padding-left: $basespace-600;
    z-index: 100;
  }
}

.mail-notification-body {
  @include m-fixed-height(510px);
  overflow-y: auto;
}

.mail-notification-title {
  text-align: center;
  font-size: $fontsize-300;
  font-weight: 600;
  margin-bottom: $basespace-200;
}

.mail-notification-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-around;
  background: $bgcolor-base;

  .footer-item {
    line-height: 60px;
  }
}
</style>

<template>
  <div
    v-loading="loading"
    class="sisense-dashboard pl-600 pr-600"
  >
    <div class="py-300">
      <h3>
        <BIcon
          rounded
          class="mr-100"
        >
          groups
        </BIcon>
        {{ $t('sisense.nextAction.activity') }}
      </h3>
      <BCard class="sisense-dashboard-chart mt-200">
        <h4 class="mb-300">
          {{ $t('general.nextAction') }}
        </h4>
        <BLayout>
          <BFlex
            :span="17"
          >
            <ElButtonGroup
              class="sisense-dashboard-button-group"
            >
              <ElButton
                :type="indexType(0)"
                class="sisense-dashboard-button"
                @click="handleClickChartIndex(0)"
              >
                {{ $t('sisense.nextAction.byDate') }}
              </ElButton>
              <ElButton
                :type="indexType(1)"
                class="sisense-dashboard-button"
                @click="handleClickChartIndex(1)"
              >
                {{ $t('sisense.nextAction.byType') }}
              </ElButton>
              <ElButton
                :type="indexType(2)"
                class="sisense-dashboard-button"
                @click="handleClickChartIndex(2)"
              >
                {{ $t('sisense.nextAction.byPriority') }}
              </ElButton>
            </ElButtonGroup>
            <ThemeProvider
              v-if="!loading"
              :theme="theme"
            >
              <ColumnChart
                v-if="chartIndex === 0"
                :data-set="dataSource"
                :data-options="reservedAtChartProps.dataOptions"
                :style-options="styleOptions"
                :filters="multiFilters"
                :on-data-point-click="(dataPoint, event) => {
                  handleClickReservedAt(dataPoint);
                }"
              />
              <BarChart
                v-if="chartIndex === 1"
                :data-set="dataSource"
                :data-options="actionTypeNameChartProps.dataOptions"
                :style-options="styleOptions"
                :filters="multiFilters"
                :on-data-point-click="(dataPoint, event) => {
                  handleClickActionTypeName(dataPoint);
                }"
              />
              <BarChart
                v-if="chartIndex === 2"
                :data-set="dataSource"
                :data-options="priorityNameChartProps.dataOptions"
                :style-options="styleOptions"
                :filters="multiFilters"
                :on-data-point-click="(dataPoint, event) => {
                  handleClickPriorityName(dataPoint);
                }"
              />
            </ThemeProvider>
          </BFlex>
          <BFlex
            :span="6"
            class="ml-600"
          >
            <MemberFilterTile
              :attribute="memberFilterTileProp.attribute"
              :on-change="memberFilterTileProp.onChange"
              :data-source="memberFilterTileProp.dataSource"
              :title="memberFilterTileProp.title"
              :filter="memberFilterTileProp.filter"
            />
          </BFlex>
        </BLayout>
      </BCard>
    </div>
    <div class="py-300">
      <h3>
        <BIcon
          rounded
          class="mr-100"
        >
          tv_options_edit_channels
        </BIcon>
        {{ $t('sisense.nextAction.list') }}
      </h3>
      <BCard class="next-action-list mt-200 mb-600">
        <div
          v-infinite-scroll="loadNextActions"
          :infinite-scroll-disabled="scrollDisabled"
        >
          <NextActionComponent
            v-for="nextAction in sortedNextActions"
            :key="nextAction.id"
            :next-action="nextAction"
          />
          <BLayout
            v-if="$wait.is('fetchNextActions')"
            class="py-200"
            justify-center
          >
            <BElementIcon name="loading" />
          </BLayout>
          <div
            v-else-if="sortedNextActions?.length == 0"
            class="pt-300 pb-600 pl-600"
          >
            {{ $t(`sisense.empty`) }}
          </div>
        </div>
      </BCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { measureFactory, Filter, filterFactory } from '@sisense/sdk-data';
import {
  ThemeProvider,
  ColumnChart,
  BarChart,
  MemberFilterTile,
  type ColumnChartProps,
  type BarChartProps,
  type MemberFilterTileProps,
} from '@sisense/sdk-ui-vue';
import { ref, computed, onBeforeMount, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { NextAction } from '@/api/openapi';
import { NextActionApiService } from '@/api/user/resources/next_action';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import * as DM from '@/data-models/bc-data-source-V-0-0-1';
import BLayout from '@/plugins/biscuet-materials/components/atoms/BLayout.vue';
import { formatShorterDate } from '@/utils/date-time';
import NextActionComponent from './NextAction.vue';

const i18n = useI18n();
const { currentUser, fetchCurrentUserPromise } = useCurrentUser();
const { doActionWithWait, wait } = useWait();
const chartIndex = ref(0);
const nextActions = ref<NextAction[]>([]);
const page = ref(1);
const totalCount = ref(0);

const styleOptions = {
  height: 400,
  navigator: {
    enabled: false,
  },
};
const theme = ref({
  palette: {
    variantColors: ['#2CBFB7'],
  },
});

const dataSource = DM.DataSource;
const isDone = ref<boolean>(false);
const includeMembers = ref<string[]>([]);
const excludeMembers = ref<string[]>([]);
const selectedReservedAt = ref<string>();
const selectedActionTypeName = ref<string>();
const selectedPriorityName = ref<string>();

const isDoneFilter = ref<Filter>(filterFactory.equals(DM.NextAction.is_done, 'false'));
const memberFilter = ref<Filter>(filterFactory.members(DM.NextAction.owner_name, []));
const reservedAtFilter = ref<Filter>(filterFactory.members(DM.NextAction.reserved_at_jst.Days, []));
const actionTypeNameFilter = ref<Filter>(filterFactory.members(DM.NextAction.action_type_name, []));
const priorityNameFilter = ref<Filter>(filterFactory.members(DM.NextAction.priority_name, []));

const multiFilters = computed<Filter[]>(() => {
  return [
    isDoneFilter.value,
    memberFilter.value,
    reservedAtFilter.value,
    actionTypeNameFilter.value,
    priorityNameFilter.value,
  ];
});

// 所有者フィルター
const handleChangeMemberFilter = (filter) => {
  memberFilter.value = filter;
  if (filter.config.disabled) {
    includeMembers.value = [];
    excludeMembers.value = [];
  } else if (filter.config.excludeMembers) {
    includeMembers.value = [];
    excludeMembers.value = filter.members;
  } else {
    includeMembers.value = filter.members;
    excludeMembers.value = [];
  }
};
const memberFilterTileProp = ref<MemberFilterTileProps>({
  dataSource,
  title: i18n.t('sisense.nextAction.owner'),
  attribute: DM.NextAction.owner_name,
  filter: memberFilter,
  onChange: handleChangeMemberFilter,
});

// 次のアクション:日毎
const reservedAtChartProps = ref<ColumnChartProps>({
  dataOptions: {
    category: [DM.NextAction.reserved_at_jst.Days],
    value: [
      measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
    ],
    breakBy: [],
  },
});

// 次のアクション:タイプ別
const actionTypeNameChartProps = ref<BarChartProps>({
  dataOptions: {
    category: [DM.NextAction.action_type_name],
    value: [{
      column: measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
      sortType: 'sortDesc',
    }],
    breakBy: [],
  },
});

// 次のアクション:優先度別
const priorityNameChartProps = ref<BarChartProps>({
  dataOptions: {
    category: [DM.NextAction.priority_name],
    value: [{
      column: measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
      sortType: 'sortDesc',
    }],
    breakBy: [],
  },
});

onBeforeMount(async () => {
  await doActionWithWait('onMountedWait', async () => {
    await fetchCurrentUserPromise;
  });
});

watch([
  memberFilter,
  reservedAtFilter,
  actionTypeNameFilter,
  priorityNameFilter,
], () => {
  page.value = 1;
  totalCount.value = 0;
  fetchNextActions();
});

watch(currentUser, () => {
  if (currentUser.value) {
    memberFilter.value = filterFactory.members(DM.NextAction.owner_name, [currentUser.value.name]);
    handleChangeMemberFilter(memberFilter.value);
  }
});

const loading = computed(() => {
  return wait.is(['onMountedWait']);
});

const sortedNextActions = computed(() => {
  return nextActions.value?.slice(0).sort((a, b) => a.name < b.name ? -1 : 1);
});

const scrollDisabled = computed(() => {
  return wait.is('fetchNextActions') || nextActions.value.length >= totalCount.value;
});

const loadNextActions = () => {
  if (scrollDisabled.value) return;
  page.value += 1;
  fetchNextActions();
};

const fetchNextActions = async () => {
  await doActionWithWait('fetchNextActions', async () => {
    const api = new NextActionApiService();
    const { data } = await api.getNextActions({
      request: {
        page: page.value,
        isDone: isDone.value,
        includeOwnerName: includeMembers.value,
        excludeOwnerName: excludeMembers.value,
        reservedAt: selectedReservedAt.value,
        actionTypeName: selectedActionTypeName.value,
        priorityName: selectedPriorityName.value,
      },
    });
    if (page.value === 1) nextActions.value = [];
    nextActions.value = [...nextActions.value, ...data.nextActions];
    totalCount.value = data.meta.totalCount || 0;
  });
};

const indexType = (index: number) => {
  return chartIndex.value === index ? 'primary': 'default';
};

const handleClickChartIndex = (index: number) => {
  chartIndex.value = index;
};

const handleClickReservedAt = (dataPoint) => {
  const reservedDate = formatShorterDate(dataPoint.categoryValue);
  if (selectedReservedAt.value) {
    selectedReservedAt.value = undefined;
    reservedAtFilter.value = filterFactory.members(DM.NextAction.reserved_at_jst.Days, []);
  } else {
    selectedReservedAt.value = reservedDate;
    reservedAtFilter.value = filterFactory.dateRange(DM.NextAction.reserved_at_jst.Days, reservedDate, reservedDate);
  }
};

const handleClickActionTypeName = (dataPoint) => {
  if (selectedActionTypeName.value) {
    selectedActionTypeName.value = undefined;
    actionTypeNameFilter.value = filterFactory.members(DM.NextAction.action_type_name, []);
  } else {
    selectedActionTypeName.value = dataPoint.categoryValue;
    actionTypeNameFilter.value = filterFactory.members(DM.NextAction.action_type_name, [selectedActionTypeName.value]);
  }
};

const handleClickPriorityName = (dataPoint) => {
  if (selectedPriorityName.value) {
    selectedPriorityName.value = undefined;
    priorityNameFilter.value = filterFactory.members(DM.NextAction.priority_name, []);
  } else {
    selectedPriorityName.value = dataPoint.categoryValue;
    priorityNameFilter.value = filterFactory.members(DM.NextAction.priority_name, [selectedPriorityName.value]);
  }
};
</script>

<style lang="scss" scoped>
.sisense-dashboard {
  min-height: 100%;
  background-color: #F5F7FA;
}

.sisense-dashboard-chart {
  height: 540px;
}

.sisense-dashboard-button-group {
  display: flex;
  width: 100%;

  .el-button {
    &.el-button--default {
      background-color: var(--el-fill-color-blank);
    }
    &.el-button--primary {
      color: $uranus;
      font-weight: bold;
      background-color: var(--el-color-primary-light-9);
    }
  }
}

.sisense-dashboard-button {
  flex: 1;
}

.next-action-list {
  padding: 0;
  padding-top: $basespace-200;

  :deep(.task-container) {
    padding: $basespace-300 $basespace-600;

    &:not(:last-child) {
      border-bottom: 1px solid $bdcolor-light;
    }
  }
}
</style>

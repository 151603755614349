<template>
  <div v-if="displayOnly">
    {{ daysOfWeekString }}
  </div>
  <ElCheckboxGroup
    v-else
    v-model="model"
  >
    <ElCheckboxButton
      v-for="day in DayOfWeek"
      :key="day"
      :value="day"
    >
      {{ $t(`general.dayOfWeek.enum.${day}`) }}
    </ElCheckboxButton>
  </ElCheckboxGroup>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DayOfWeek } from '@/api/openapi';

type TProps = {
  required?: boolean;
  displayOnly?: boolean;
};
const props = defineProps<TProps>();

const model = defineModel<DayOfWeek[]>({ default: [] });
const valid = defineModel<boolean>('valid', { default: false });

const i18n = useI18n();

watch(model, (newValue) => {
  if (props.required) {
    valid.value = newValue.length > 0;
  } else {
    valid.value = true;
  }
}, { immediate: true });

const daysOfWeekString = computed(() => {
  return model.value.map((day) => i18n.t(`general.dayOfWeek.enum.${day}`)).join(', ');
});
</script>

<template>
  <BLayout
    class="task-container"
    align-center
  >
    <div class="task-user-area">
      <BUserTooltip
        :image-url="(owner || {}).avatarUrl"
        :name="(owner || {}).name"
        :user-id="(owner || {}).id"
        :size="40"
      />
    </div>
    <div class="task-info-area">
      <div
        v-if="$slots.action"
        class="task-action-area"
        @click.stop
      >
        <slot name="action" />
      </div>
      <div class="task-info-main">
        <div class="task-title-area">
          <BCustomIcon
            v-if="isSequence"
            icon-class="b-sequence"
            size="mini"
          />
          <div class="task-title">
            {{ title }}
          </div>
        </div>
        <div class="task-state-area">
          <div
            v-if="$slots.status"
            class="task-status"
          >
            <slot name="status" />
          </div>
          {{ dateTimeForDisplay }}
        </div>
      </div>
      <div
        v-if="$slots.optionalActions"
        class="task-optional-actions"
        @click.stop
      >
        <slot name="optionalActions" />
      </div>
    </div>
    <div
      class="task-content-area"
    >
      <div
        v-if="isContentEditable"
        class="task-editable-content"
        @click.stop="handleEditorClick"
        @focus.stop
      >
        <BEditableContentTextarea
          v-model="content"
          :placeholder="$t('nextAction.content')"
        />
      </div>
      <div v-else-if="contentExpandable && content">
        <BExpandableText :text="content" />
      </div>
      <div
        v-else
        class="task-content"
      >
        {{ content }}
      </div>
    </div>
    <div
      v-if="linkToText != null"
      class="task-link-to-area"
    >
      <span>{{ linkToText }}</span>
      <BIcon size="small">
        chevron_right
      </BIcon>
    </div>
    <div
      v-if="!contentExpandable"
      class="task-menu-area"
      @click.stop
    >
      <BMoreMenu
        v-if="isDeletable"
        attach
      >
        <BBtn
          flat
          fit
          size="small"
          @click="handleDeleteClick"
        >
          {{ $t(`general.delete.do`) }}
        </BBtn>
      </BMoreMenu>
    </div>
  </BLayout>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { UserForDisplay } from '@/api/openapi';
import { formatShorterDateTime } from '@/utils/date-time';

type TProps = {
  owner: UserForDisplay;
  title: string;
  dateTime: string;
  linkToText?: string;
  isSequence?: boolean;
  isContentEditable?: boolean;
  isDeletable?: boolean;
  contentExpandable?: boolean;
};
type TEmit = {
  'click:editor': [];
  'click:delete': [];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();
const content = defineModel<string>('content');

const dateTimeForDisplay = computed(() => {
  return formatShorterDateTime(props.dateTime);
});

const handleEditorClick = () => {
  emit('click:editor');
};
const handleDeleteClick = () => {
  emit('click:delete');
};
</script>

<style lang="scss" scoped>
.task-container {
  padding: $basespace-100 $basespace-600;

  .task-user-area {
    padding-right: $basespace-300;
  }

  .task-info-area {
    @include m-fixed-width(280px);
    display: flex;
    padding-right: $basespace-200;

    .task-action-area {
      padding-right: $basespace-200;
      @include m-flex-align-center;
    }

    .task-info-main {
      @include m-truncate-text;
    }
    .task-title-area {
      padding-bottom: $basespace-50;
      display: flex;
      .b-sequence {
        padding-right: $basespace-50;
      }
      .task-title {
        @include m-truncate-text;
        font-weight: 600;
      }
    }
    .task-state-area {
      display: flex;
      color: $textcolor-light;
      font-size: $fontsize-100;
      .task-status {
        padding-right: $basespace-100;
      }
    }
    .task-optional-actions {
      @include m-flex-align-center;
      margin-left: auto;
    }
  }

  .task-content-area {
    width: 100%;
    padding-right: $basespace-200;
    .task-content {
      @include m-break-word-pre-wrap;
      padding: 5px 9px; // 編集可能なcontentと幅を揃える
      &.not-collapsed {
        @include m-truncate-text-multi-line(2);
      }
    }
  }

  .task-menu-area {
    padding: $basespace-100;
    @include m-fixed-width(32px);
  }
  .task-link-to-area {
    display: none;
    @include m-fixed-width(80px);
    font-size: $fontsize-100;
    line-height: 20px; // iconと高さを揃えるため
    margin-left: auto;
  }
  &:hover {
    .task-link-to-area {
      display: flex;
      justify-content: right;
    }
  }
}
</style>

import { useInputValidations } from '@/composable/input-validations';

const {
  requiredRule, urlRule, emailRule,
  emailTest, lowercaseTest,
  phoneRule, numberRule,
} = useInputValidations();

/**
 * @duplicated '@/composable/input-validations' を使ってください
 */
const mixin = {
  methods: {
    requiredRule(value) {
      return requiredRule(value);
    },
    urlRule(value) {
      return urlRule(value);
    },
    phoneRule(value) {
      return phoneRule(value);
    },
    emailRule(value) {
      return emailRule(value);
    },
    emailTest(str) {
      return emailTest(str);
    },
    lowercaseTest(str) {
      return lowercaseTest(str);
    },
    kanaRule(value) {
      if (!value) return true;
      // eslint-disable-next-line no-irregular-whitespace
      const pattern = /^[ぁ-んァ-ンー 　]+$/; // ひらがな、カタカナ、長音符、半角スペース、全角スペースのみ
      return pattern.test(value) || this.$t(`validation.kana`);
    },
    slugRule(value) {
      if (!value) return true; // ハイフンでの開始終了不可、小文字、英数字
      const pattern = /^[a-z0-9]{1}[a-z0-9-]{2,30}[a-z0-9]{1}$/;
      return pattern.test(value) || this.$t(`validation.slug`);
    },
    numberRule(value) {
      return numberRule(value);
    },
  },
};

export default mixin;

import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base';
import { GetUsersForAdmin200Response, UserForAdminApi, UserForAdminApiGetUsersForAdminRequest } from '@/api/openapi';

export class UserApiService extends ApiService {
  api = new UserForAdminApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getUsersForAdmin({
    request,
    options,
    errorHandlers,
  }: ApiArguments<UserForAdminApiGetUsersForAdminRequest>): Promise<AxiosResponse<GetUsersForAdmin200Response>> {
    return this.checkResponse<GetUsersForAdmin200Response>(
      this.api.getUsersForAdmin(request, options),
      errorHandlers,
    );
  }
}

export default {
  getUsers (args) {
    const { clientId } = args;
    return ApiBase.get(`/admin/clients/${clientId}/users`);
  },
  createUser (args) {
    const { clientId } = args;
    return ApiBase.post(`/admin/clients/${clientId}/users`, args);
  },
  updateUser (args) {
    const { id } = args;
    return ApiBase.put(`/admin/users/${id}`, args);
  },
};

import type { ActionNotification, Announcement, NotificationCommon } from '@/api/openapi';

const NOTIFICATION_TYPE = {
  actionNotification: 'action_notification',
  announcement: 'announcement',
} as const;

type TNotification = ActionNotification | Announcement;
type NotificationType = typeof NOTIFICATION_TYPE[keyof typeof NOTIFICATION_TYPE];

const isAnnouncement = (notification: NotificationCommon): notification is Announcement => 'startAt' in notification;
const isActionNotification = (notification: NotificationCommon): notification is ActionNotification => !isAnnouncement(notification);

export type {
  TNotification,
  NotificationType,
};
export {
  NOTIFICATION_TYPE,
  isAnnouncement,
  isActionNotification,
};

import ApiService, { ApiArguments } from '@/api/api_service';
import {
  LeadViewLogApi,
  LeadViewLogApiPostLeadViewLogRequest,
  PostLeadViewLog201Response,
} from '@/api/openapi';

export class LeadViewLogApiService extends ApiService {
  api = new LeadViewLogApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async postLeadViewLog({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadViewLogApiPostLeadViewLogRequest>) {
    return this.checkResponse<PostLeadViewLog201Response>(
      this.api.postLeadViewLog(request, options),
      errorHandlers,
    );
  }
}

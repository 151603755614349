/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetMailNotificationCount200Response } from '../models';
// @ts-ignore
import { GetMailNotifications200Response } from '../models';
/**
 * MailNotificationApi - axios parameter creator
 * @export
 */
export const MailNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メール通知件数取得API
         * @summary メール通知件数取得API
         * @param {number} [sentUserId] 送信者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailNotificationCount: async (sentUserId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_notifications/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (sentUserId !== undefined) {
                localVarQueryParameter['sentUserId'] = sentUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール通知一覧取得API
         * @summary メール通知一覧取得API
         * @param {number} [sentUserId] 送信者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailNotifications: async (sentUserId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (sentUserId !== undefined) {
                localVarQueryParameter['sentUserId'] = sentUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール通知確認API
         * @summary メール通知確認API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailNotificationConfirmed: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/mail_notifications/confirmed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailNotificationApi - functional programming interface
 * @export
 */
export const MailNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * メール通知件数取得API
         * @summary メール通知件数取得API
         * @param {number} [sentUserId] 送信者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailNotificationCount(sentUserId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMailNotificationCount200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailNotificationCount(sentUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メール通知一覧取得API
         * @summary メール通知一覧取得API
         * @param {number} [sentUserId] 送信者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailNotifications(sentUserId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMailNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailNotifications(sentUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メール通知確認API
         * @summary メール通知確認API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailNotificationConfirmed(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailNotificationConfirmed(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailNotificationApi - factory interface
 * @export
 */
export const MailNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailNotificationApiFp(configuration)
    return {
        /**
         * メール通知件数取得API
         * @summary メール通知件数取得API
         * @param {number} [sentUserId] 送信者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailNotificationCount(sentUserId?: number, options?: any): AxiosPromise<GetMailNotificationCount200Response> {
            return localVarFp.getMailNotificationCount(sentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * メール通知一覧取得API
         * @summary メール通知一覧取得API
         * @param {number} [sentUserId] 送信者ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailNotifications(sentUserId?: number, options?: any): AxiosPromise<GetMailNotifications200Response> {
            return localVarFp.getMailNotifications(sentUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * メール通知確認API
         * @summary メール通知確認API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailNotificationConfirmed(options?: any): AxiosPromise<void> {
            return localVarFp.postMailNotificationConfirmed(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailNotificationApi - interface
 * @export
 * @interface MailNotificationApi
 */
export interface MailNotificationApiInterface {
    /**
     * メール通知件数取得API
     * @summary メール通知件数取得API
     * @param {number} [sentUserId] 送信者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailNotificationApiInterface
     */
    getMailNotificationCount(sentUserId?: number, options?: AxiosRequestConfig): AxiosPromise<GetMailNotificationCount200Response>;

    /**
     * メール通知一覧取得API
     * @summary メール通知一覧取得API
     * @param {number} [sentUserId] 送信者ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailNotificationApiInterface
     */
    getMailNotifications(sentUserId?: number, options?: AxiosRequestConfig): AxiosPromise<GetMailNotifications200Response>;

    /**
     * メール通知確認API
     * @summary メール通知確認API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailNotificationApiInterface
     */
    postMailNotificationConfirmed(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for getMailNotificationCount operation in MailNotificationApi.
 * @export
 * @interface MailNotificationApiGetMailNotificationCountRequest
 */
export interface MailNotificationApiGetMailNotificationCountRequest {
    /**
     * 送信者ID
     * @type {number}
     * @memberof MailNotificationApiGetMailNotificationCount
     */
    readonly sentUserId?: number
}

/**
 * Request parameters for getMailNotifications operation in MailNotificationApi.
 * @export
 * @interface MailNotificationApiGetMailNotificationsRequest
 */
export interface MailNotificationApiGetMailNotificationsRequest {
    /**
     * 送信者ID
     * @type {number}
     * @memberof MailNotificationApiGetMailNotifications
     */
    readonly sentUserId?: number
}

/**
 * MailNotificationApi - object-oriented interface
 * @export
 * @class MailNotificationApi
 * @extends {BaseAPI}
 */
export class MailNotificationApi extends BaseAPI implements MailNotificationApiInterface {
    /**
     * メール通知件数取得API
     * @summary メール通知件数取得API
     * @param {MailNotificationApiGetMailNotificationCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailNotificationApi
     */
    public getMailNotificationCount(requestParameters: MailNotificationApiGetMailNotificationCountRequest = {}, options?: AxiosRequestConfig) {
        return MailNotificationApiFp(this.configuration).getMailNotificationCount(requestParameters.sentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール通知一覧取得API
     * @summary メール通知一覧取得API
     * @param {MailNotificationApiGetMailNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailNotificationApi
     */
    public getMailNotifications(requestParameters: MailNotificationApiGetMailNotificationsRequest = {}, options?: AxiosRequestConfig) {
        return MailNotificationApiFp(this.configuration).getMailNotifications(requestParameters.sentUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール通知確認API
     * @summary メール通知確認API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailNotificationApi
     */
    public postMailNotificationConfirmed(options?: AxiosRequestConfig) {
        return MailNotificationApiFp(this.configuration).postMailNotificationConfirmed(options).then((request) => request(this.axios, this.basePath));
    }
}

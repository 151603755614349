<template>
  <div class="users">
    <div class="filters">
      <ElInput
        v-model="filters.email"
        class="filters-email"
        :placeholder="$t('userForAdmin.headers.email')"
        :prefix-icon="Search"
        clearable
        @change="fetchUsers"
      />
    </div>
    <ElTable
      v-loading="$wait.is('fetchUsersWait')"
      :data="items"
    >
      <ElTableColumn
        :label="$t('userForAdmin.headers.client')"
        fixed
      >
        <ElTableColumn
          class-name="client-name"
          width="250"
          :label="$t('userForAdmin.headers.clientName')"
          fixed
        >
          <template #default="scope">
            <RouterLink :to="{ name: 'AdminClient', params: { id: scope.row.clientId } }">
              {{ scope.row.clientName }}
            </RouterLink>
          </template>
        </ElTableColumn>
        <ElTableColumn
          class-name="client-expired"
          width="100"
          fixed
          :label="$t('userForAdmin.headers.clientExpired')"
        >
          <template #default="scope">
            <BStatus
              :status="scope.row.isExpiredClient ? 'inactive' : 'active'"
              :status-name="$t(`client.expiredStatus.${scope.row.isExpiredClient ? 'expired' : 'active'}`)"
            />
          </template>
        </ElTableColumn>
      </ElTableColumn>
      <ElTableColumn
        prop="name"
        class-name="user-name"
        width="200"
        :label="$t('userForAdmin.headers.userName')"
        fixed
      />
      <ElTableColumn
        prop="email"
        class-name="email"
        width="350"
        :label="$t('userForAdmin.headers.email')"
      />
      <ElTableColumn
        class-name="role"
        width="100"
        :label="$t('userForAdmin.headers.role')"
      >
        <template #default="scope">
          {{ $t(`user.enum.${scope.row.role}`) }}
        </template>
      </ElTableColumn>
      <ElTableColumn
        class-name="invitation-status"
        width="100"
        :label="$t('userForAdmin.headers.invitationStatus')"
      >
        <template #default="scope">
          <BStatus
            :status="scope.row.isInviting ? 'pending' : 'complete'"
            :status-name="$t(`user.invitationStatus.${scope.row.isInviting ? 'inviting' : 'invited'}`)"
          />
        </template>
      </ElTableColumn>
      <ElTableColumn
        class-name="active"
        width="100"
        :label="$t('userForAdmin.headers.active')"
      >
        <template #default="scope">
          <BStatus
            :status="scope.row.isActive ? 'active' : 'inactive'"
            :status-name="$t(`user.active.${scope.row.isActive ? 'active' : 'inactive'}`)"
          />
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('userForAdmin.headers.sisense')">
        <ElTableColumn
          class-name="sisense-group"
          width="100"
          :label="$t('userForAdmin.headers.sisenseGroup')"
        >
          <template #default="scope">
            <template v-if="scope.row.hasSisenseUser">
              {{ scope.row.sisenseGroups.join(', ') }}
            </template>
            <template v-else>
              {{ $t('general.none') }}
            </template>
          </template>
        </ElTableColumn>
        <ElTableColumn
          class-name="sisense-status"
          width="100"
          :label="$t('userForAdmin.headers.sisenseStatus')"
        >
          <template #default="scope">
            <template v-if="scope.row.hasSisenseUser">
              <BStatus
                :status="getSisenseStatus(scope.row)"
                :status-name="$t(`sisense.status.${getSisenseStatus(scope.row)}`)"
              />
            </template>
            <template v-else>
              {{ $t('general.none') }}
            </template>
          </template>
        </ElTableColumn>
        <ElTableColumn
          class-name="sisense-role"
          width="100"
          :label="$t('userForAdmin.headers.sisenseRole')"
        >
          <template #default="scope">
            <template v-if="scope.row.hasSisenseUser">
              {{ $t(`sisense.roles.${camelCase(scope.row.sisenseRole)}`) }}
            </template>
            <template v-else>
              {{ $t('general.none') }}
            </template>
          </template>
        </ElTableColumn>
      </ElTableColumn>
    </ElTable>
    <div class="pagination">
      <ElPagination
        :current-page="paginationMeta.currentPage"
        :page-size="paginationMeta.limitValue"
        layout="total, prev, pager, next, jumper"
        size="small"
        :total="paginationMeta.totalCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Search } from '@element-plus/icons-vue';
import { ElTableColumn } from 'element-plus';
import { camelCase } from 'lodash';
import { onMounted, ref } from 'vue';
import { UserApiService } from '@/api/admin/resources/user';
import { PaginationMeta, UserForAdmin } from '@/api/openapi';
import { useWait } from '@/composable/vue-wait';
import BStatus from '@/plugins/biscuet-materials/components/atoms/BStatus.vue';

const { doActionWithWait } = useWait();
const api = new UserApiService();

const filters = ref({
  email: '',
});
const page = ref(1);
const items = ref<UserForAdmin[]>([]);
const paginationMeta = ref<PaginationMeta>({
  totalPages: 0,
  totalCount: 0,
  limitValue: 50,
  currentPage: 1,
  isEmpty: false,
});

const fetchUsers = async () => {
  await doActionWithWait('fetchUsersWait', async () => {
    const email = filters.value.email || undefined;
    const { data: { users, meta } } = await api.getUsersForAdmin({
      request: { page: page.value, email },
    });
    items.value = users;
    paginationMeta.value = meta;
  });
};
const handleCurrentChange = async (currentPage: number) => {
  page.value = currentPage;
  await fetchUsers();
};

const getSisenseStatus = (user: UserForAdmin) => {
  if (!user.hasSisenseUser) return null;
  return user.sisenseActive ? 'active' : 'pending';
};

onMounted(async () => {
  await fetchUsers();
});
</script>

<style lang="scss" scoped>
.filters {
  @include m-flex-align-center;
  @include m-flex-justify-right;
  padding: $basespace-200;

  &-email {
    width: 20rem;
  }

  .b-btn {
    margin-left: $basespace-200;
    // inputの高さと同じにする
    height: 40px;
    // 視差調整のため、枠線なしにする
    border-color: $bgcolor-base;
  }
}

.el-table {
  // 検索エリア、テーブルヘッダー、ページングの高さを減算
  height: calc(100vh - 64px - 48px - 80px);

  :deep(.el-table__body) {
    .cell {
      @include m-truncate-text;
    }
  }
}

.pagination {
  padding-top: $basespace-200;
  @include m-flex-center;
}
</style>

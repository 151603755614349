<template>
  <BIconWithActionMenu
    :width="320"
    :auto-fetch-interval="30000"
    :show-dot-badge="hasUnreadNotifications"
    placement="bottom-end"
    @auto-fetch-interval-passed="poolFetchUnreadNotificationsCount"
    @after-open="handleAfterOpen"
    @after-close="handleAfterClose"
  >
    <template #icon>
      <BIcon outlined>
        notifications
      </BIcon>
    </template>
    <ElTabs
      v-model="activeTab"
      stretch
    >
      <ElTabPane
        v-for="(notificationType, notificationTypeKey) in NOTIFICATION_TYPE"
        :key="notificationTypeKey"
        :name="notificationType"
        lazy
      >
        <template #label>
          {{ i18n.t(`notification.notificationType.${notificationTypeKey}`) }}
          <ElTag
            v-if="unreadCount?.[notificationTypeKey] > 0"
            type="danger"
            effect="light"
            size="small"
            round
          >
            {{ unreadCount?.[notificationTypeKey] }}
          </ElTag>
        </template>
        <NotificationList :notification-type="notificationType" />
      </ElTabPane>
    </ElTabs>
  </BIconWithActionMenu>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { NotificationApiService } from '@/api/user/resources/notification';
import NotificationList from './notificationPopover/NotificationList.vue';
import { NOTIFICATION_TYPE, NotificationType } from './notificationPopover/types';

const i18n = useI18n();

const unreadCount = ref<{ actionNotification: number; announcement: number; }>();
const activeTab = ref<NotificationType>('action_notification');
const isPopoverOpen = ref(false);
const hasUnreadNotifications = computed(() => {
  return unreadCount.value?.actionNotification > 0 || unreadCount.value?.announcement > 0;
});

onBeforeMount(async () => {
  await fetchUnreadNotificationsCount();
});
watch(isPopoverOpen, async (newVal) => {
  if (newVal) {
    await fetchUnreadNotificationsCount();
  }
});

const api = new NotificationApiService();
const fetchUnreadNotificationsCount = async () => {
  const { data } = await api.getUnreadNotificationsCount();
  unreadCount.value = {
    actionNotification: data.numberOfActionNotifications,
    announcement: data.numberOfAnnouncements,
  };
};

const handleAfterOpen = () => {
  isPopoverOpen.value = true;
};
const handleAfterClose = () => {
  isPopoverOpen.value = false;
};
const poolFetchUnreadNotificationsCount = async () => {
  // NOTE: ポップオーバーが開いている場合はポーリングしないでおく（読み込み済みのリストと数量の表示がズレるので）
  if (isPopoverOpen.value) return;
  await fetchUnreadNotificationsCount();
};
</script>

<style lang="scss" scoped>
.b-icon {
  cursor: pointer;
}
.el-tabs {
  :deep(.el-tabs__header) {
    margin-bottom: 0;
  }
  :deep(.el-tabs__item) {
    padding: 0;
    padding-top: $basespace-100;
  }
}
.el-tag {
  margin-left: $basespace-200;
  margin-bottom: 4px;
  padding: $basespace-50 $basespace-100;
}
.b-icon-with-action-menu-container {
  @include m-flex-center;
}
</style>

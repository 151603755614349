<template>
  <div class="step-aggregate">
    <div class="step-aggregate-name">
      {{ title }}
    </div>
    <div class="step-aggregate-value">
      {{ count }}
      <span
        v-if="displayRate"
        class="step-aggregate-rate"
      >{{ displayRate }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type TProps = {
  title: string;
  count: number;
  rate?: number;
};
const props = defineProps<TProps>();

const displayRate = computed(() => props.rate != null ? `(${props.rate.toFixed(1)}%)` : '');
</script>
